
import {
  Component,
  Vue,
  Getter,
  Mutation,
  Watch,
} from 'nuxt-property-decorator';

import Layout from '@/router/layouts/main.vue';
import PageHeader from '@/components/page-header.vue';
import GeneralTable from '@/components/tables/general-table.vue';
import LoaderBlock from '@/components/loader.vue';
import appConfig from '@/app.config.json';
import { ICartTransaction } from '@/state/modules/salesData';
import { EFetchStatus } from '@/state/types';

@Component({
  metaInfo: {
    title: 'Advanced Table',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: { Layout, PageHeader, GeneralTable, LoaderBlock },
})
export default class cartSales extends Vue {
  @Getter('getTransactions', { namespace: 'salesData' })
  readonly _tableData!: (ICartTransaction & {
    rawData: string;
    modStatus: string;
  })[];

  @Getter('checkTransactions', { namespace: 'salesData' })
  readonly _status!: EFetchStatus;

  @Mutation toggleErrorSnackbar!: (payload?: boolean) => void;

  @Watch('_status')
  showError(status) {
    if (status === 2) this.toggleErrorSnackbar();
  }
 endDateFilter = new Date();
  startDateFilter = new Date(
    new Date().setDate(this.endDateFilter.getDate() - 30)
  );
  
  get tableData() {
    const startDateFilter = new Date('2020-10-01T03:24:00');
    const endDateFilter = new Date('2100-12-31T03:24:00');
    return this._tableData
      .filter(
        (i) =>
          i.created &&
          i.created >= startDateFilter &&
          i.created <= endDateFilter
      )
      .map((i) => {
        i.rawData = i.data;
        i.modStatus = JSON.parse(i.rawData).status
          ? i.status + '/' + JSON.parse(i.rawData).status
          : '';
        return i;
      });
  }

  title = 'Cart Sales (getAllCartTransactions)';
  items = [
    // breadcrumbs
    {
      text: 'Sales',
      href: '/',
    },
    {
      text: 'Cart Sales',
      active: true,
    },
  ];

  fields = [
    { key: 'wp_id', sortable: true },
    {
      key: 'modStatus',
      sortable: true,
      label: 'Server Status/Details Status',
    },
    { key: 'currency', sortable: true },
    { key: 'name', sortable: true },
    { key: 'email', sortable: true },
    { key: 'created', sortable: true },
    { key: 'productName', sortable: true },
    { key: 'quantity', sortable: true },
    { key: 'totalUsd', sortable: true },
    { key: 'totalCrypto', sortable: true },
    { key: 'totalCryptoReceived', sortable: true },
    { key: 'conversionRate', sortable: true },
    { key: 'remainingCrypto', sortable: true },
    { key: 'discountAmtUsd', sortable: true },
    { key: 'transactionHash', sortable: true },
    { key: 'address', sortable: true },
    { key: 'rawData', sortable: false },
  ];
}
