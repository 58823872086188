
import { Component, Vue } from "vue-property-decorator";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/component_samples/page-header.vue";
import appConfig from "@/app.config.json";

/**
 * Pricing component
 */
@Component({
  metaInfo: {
    title: "Pricing",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
})
class Pricing extends Vue {
  public title = "Pricing";
  public items = [
    {
      text: "Veltrix",
      href: "/",
    },
    {
      text: "Extra Pages",
      href: "/",
    },
    {
      text: "Pricing",
      active: true,
    },
  ];

  public pricingData: PricingData[] = [
    {
      icon: "ion ion-ios-airplane",
      title: "Starter",
      price: "19",
    },
    {
      icon: "ion ion-ios-trophy",
      title: "Professional",
      price: "29",
    },
    {
      icon: "ion ion-ios-umbrella",
      title: "Enterprise",
      price: "39",
    },
    {
      icon: "ion ion-ios-cube",
      title: "Unlimited",
      price: "49",
    },
  ];
}

interface PricingData {
  title: string;
  icon: string;
  price: string | number;
}

export default Pricing;
