
import { Component, Vue, Mutation, Watch } from 'nuxt-property-decorator';

import Layout from '@/router/layouts/main.vue';
import PageHeader from '@/components/page-header.vue';
import GeneralTable from '@/components/tables/general-table.vue';
import LoaderBlock from '@/components/loader.vue';
import appConfig from '@/app.config.json';

@Component({
  metaInfo: {
    title: 'Advanced Table',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: { Layout, PageHeader, GeneralTable, LoaderBlock },
})
export default class cartAudit extends Vue {
  @Mutation toggleErrorSnackbar!: (payload?: boolean) => void;

  complete = false;
  tried = false;
  result = [];
  userIDs = '';
  userResult = {};

  get tableData() {
    return this.result.map((d: any) => {
      d.date = d.updatedDate;
      d.link = d.permalink;
      d.assignees = d.responsibleIds.map((a) => this.userResult[a] || a);
      return d;
    });
  }

  @Watch('tried')
  showError(c) {
    if (c) this.toggleErrorSnackbar();
  }

  callAPI(ext) {
    return fetch('https://www.wrike.com/api/v4/' + ext, {
      method: 'GET',
      headers: {
        Authorization:
          // this is tied to Shalom's wrike account, BE VERY CAREFUL WITH IT
          'bearer eyJ0dCI6InAiLCJhbGciOiJIUzI1NiIsInR2IjoiMSJ9.eyJkIjoie1wiYVwiOjMzMTU3NjMsXCJpXCI6ODAxNTE5MCxcImNcIjo0NjI5ODE2LFwidVwiOjEwOTI0ODU4LFwiclwiOlwiVVNcIixcInNcIjpbXCJXXCIsXCJGXCIsXCJJXCIsXCJVXCIsXCJLXCIsXCJDXCIsXCJEXCIsXCJNXCIsXCJBXCIsXCJMXCIsXCJQXCJdLFwielwiOltdLFwidFwiOjB9IiwiaWF0IjoxNjM2NTUyMTAyfQ.cj_a6nf3r5utQm2bLfY1nGwIRry6f0ASA6t3FWTBWPU',
      },
    }).then((res) => res.json());
  }

  async getData() {
    const result = await this.callAPI(
      'folders/IEADFGBTI4XGD4EQ/tasks?fields=[responsibleIds]'
    );
    this.result = result.data;
    // iterate through results
    result.data.map((d: any) =>
      // iterate through the task assignees and record the unique ones
      d.responsibleIds.map(
        (id) => (this.userIDs += this.userIDs.includes(id) ? '' : `${id},`)
      )
    );
  }

  async getUserIDs() {
    const result = await this.callAPI('contacts/' + this.userIDs);
    result.data.map((d: any) => (this.userResult[d.id] = d.firstName));
  }

  async created() {
    await this.getData();
    await this.getUserIDs();
    console.log(this.userIDs.split(',').length - 1 + ' assignees');

    if (this.result.length > 0) this.complete = true;
    else this.tried = true;
  }

  title = 'Dash';
  items = [
    // breadcrumbs
    {
      text: 'Dev Info',
      href: '/',
    },
    {
      text: this.title,
      active: true,
    },
  ];
  fields = [
    // table columns
    { key: 'assignees', sortable: true },
    { key: 'link', sortable: false },
    { key: 'customStatusId', sortable: true },
    { key: 'title', sortable: true },
    { key: 'status', sortable: true },
    { key: 'date', sortable: true, label: 'Last Updated' },
  ];
}
