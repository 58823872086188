import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { BRAND_TOKEN } from '@/middleware/helpers';
import { getCookie } from '@/middleware/auth';
import {IntrospectionFragmentMatcher} from 'apollo-cache-inmemory';
import introspectionQueryResultData from '../src/fragmentTypes.json'

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = getCookie(BRAND_TOKEN);
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : null,
    },
  });
  return forward(operation);
});

// Translates Unions and Interfaces so Apollo can understand
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
  });

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: process.env.VUE_APP_SERVER,
  // uri: process.env.VUE_APP_server,
});

// Cache implementation
const cache = new InMemoryCache({fragmentMatcher});

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: authMiddleware.concat(httpLink),
  cache,
});
