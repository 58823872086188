
import { Component, Vue } from "vue-property-decorator";

import appConfig from "@/app.config.json";

/**
 * Register-2 component
 */
@Component({
  metaInfo: {
    title: "Register-2",
    meta: [{ name: "description", content: appConfig.description }],
  },
})
class Register2 extends Vue {}

export default Register2;
