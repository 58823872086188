
/**
 * Topbar component
 */

import Vue from 'vue';
import { brandLogo, brandIcon } from '@/middleware/helpers';
import { mapGetters } from 'vuex';

export default Vue.extend({
  data() {
    return {
      icon: brandIcon,
      logo: brandLogo,
      isDev: /stage|local/.test(window.location.hostname),
    };
  },
  computed: {
    ...mapGetters('userData', ['getUser']),
  },
  methods: {
    initFullScreen() {
      document.body.classList.toggle('fullscreen-enable');
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            (Element as any).ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    toggleMenu() {
      // @ts-ignore
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      // @ts-ignore
      this.$parent.toggleRightSidebar();
    },
  },
});
