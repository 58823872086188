
import Vue from 'vue';
import appConfig from '@/app.config.json';
import { isLoggedIn } from '@/middleware/auth';

export default Vue.extend({
  name: 'App',
  computed: {
    errorMessage() {
      return this.$store.state.showErrorSnackbar;
    },
    warningMessage() {
      return this.$store.state.showWarningSnackbar;
    },
    successMessage() {
      return this.$store.state.showSuccessSnackbar;
    },
  },
  metaInfo() {
    return {
      titleTemplate(title: string) {
        return title ? `${title} | ${appConfig.title}` : appConfig.title;
      },
    };
  },
  created() {
    // fix on missing user info if page refresh
    if (isLoggedIn() && !this.$store.state.userData.loggedIn) {
      this.$store.dispatch('userData/getUserData');
    }
  }
});
