
import { Component, Vue } from 'vue-property-decorator'
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/component_samples/page-header.vue";
import appConfig from "@/app.config.json";

/**
 * Form Editor component
 */
@Component({
  metaInfo: {
    title: "Form Editor",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { ckeditor: CKEditor.component, Layout, PageHeader }
})
class Editor extends Vue{  
  title = "Form Editor"
  items: Array<object> = [
    {
          text: "Veltrix",
          href: "/"
        },
        {
          text: "Forms",
          href: "/"
        },
        {
          text: "Form Editor",
          active: true
        }
  ]
  editor: any = ClassicEditor
  editorData = "<p>Content of the editor.</p>"  
}

export default Editor
