
import Vue from 'vue';
import AmountCirculating from '@/components/widgets/amountCirculating.vue';
import EnergyTransfers from '@/components/widgets/energyTransfers.vue';
import Holders from '@/components/widgets/holders.vue';
import Percentage from '@/components/widgets/percentage.vue';
import Layout from '@/router/layouts/main.vue';
import appConfig from '@/app.config.json';

/**
 * Basic Email component
 */
export default Vue.extend({
  metaInfo: {
    title: 'Basic Email',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    AmountCirculating,
    EnergyTransfers,
    Holders,
    Percentage,
  },
  data: () => {
    return {
      title: 'Basic Email',
      items: [
        {
          text: 'Veltrix',
          href: '/',
        },
        {
          text: 'Email Templates',
          href: '/',
        },
        {
          text: 'Basic Email',
          active: true,
        },
      ],
      cards: [
        {
          title: 'Widget #1',
          src: AmountCirculating,
          flex: 3,
        },
        {
          title: 'Widget #2',
          src: EnergyTransfers,
          flex: 3,
        },
        {
          title: 'Widget #3',
          src: Holders,
          flex: 3,
        },
        {
          title: 'Widget #4',
          src: Percentage,
          flex: 3,
        },
      ],
    };
  },
});
