
import Vue from 'vue';
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/component_samples/page-header.vue";
import appConfig from "@/app.config.json";

import barChart from "./bar-chart.vue";
import lineChart from "./line-chart.vue";
import pieChart from "./pie-chart.vue";
import donutChart from "./donut-chart.vue";
import polarChart from "./polar-chart.vue";
import radarChart from "./radar-chart.vue";

/**
 * Chart-js chart component
 */
export default Vue.extend({
  metaInfo: {
    title: "Chartjs chart",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
    barChart,
    lineChart,
    pieChart,
    donutChart,
    polarChart,
    radarChart
  },
  data() {
    return {
      title: "Chartjs Charts",
      items: [
        {
          text: "Veltrix",
          href: "/"
        },
        {
          text: "Charts",
          href: "/"
        },
        {
          text: "Chartjs Charts",
          active: true
        }
      ]
    };
  }
});
