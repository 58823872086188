
import { Component, Vue, Mutation} from "nuxt-property-decorator";

import Layout from "@/router/layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import AccountingTable from "@/components/tables/accounting-table.vue";
import AccountingCard from "@/components/accounting-card.vue";
import LoaderBlock from "@/components/loader.vue";
import appConfig from "@/app.config.json";

@Component({
  metaInfo: {
    title: "Accounting Dashboard",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    AccountingTable,
    LoaderBlock,
    AccountingCard,
  },
})
export default class accountingDashboard extends Vue {
  @Mutation toggleErrorSnackbar!: (payload?: boolean) => void;

  title = "Accounting Dashboard";
  items = [
    // breadcrumbs
    {
      text: "Sales",
      href: "/",
    },
    {
      text: "Accounting Dashboard",
      active: true,
    },
  ];
}
