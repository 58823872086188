
import { Component, Vue } from "vue-property-decorator";

import appConfig from "@/app.config.json";

/**
 * Maintenance page
 */
@Component({
  metaInfo: {
    title: "Maintenance",
    meta: [{ name: "description", content: appConfig.description }],
  },
})
class Maintenance extends Vue {}

export default Maintenance;
