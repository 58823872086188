export const brandName = process.env.VUE_APP_BRAND.toLowerCase();
export const isProd = process.env.NODE_ENV === 'production';
export const BRAND_TOKEN = `${brandName}-token${isProd ? '' : '-stage'}`;
export const copyright = `${new Date().getFullYear()} ${brandName}`;

export const brandURL = {
  connect: 'connectblockchain.net',
  green: 'share.green',
}[brandName.toLowerCase()];

export const brandLogo = require(`@/static/assets/images/brands/${brandName}-logo.svg`);
export const brandIcon = require(`@/static/assets/images/brands/${brandName}-icon.svg`);
export const convertIcon = require(`@/static/assets/images/convert.svg`);


export function redirectSubdomain(subURL, path = '', getString = true) {
  const local = window.location.host.includes('localhost')
    ? ''
    : window.location.host.split('.');
  let redirect = `${subURL}.${brandURL}`;
  if (local.length === 4) {
    redirect = `${local[0]}.${subURL}.${local[2]}.${local[3]}`;
  }
  if (local.length === 3) {
    redirect = `${subURL}.${local[1]}.${local[2]}`;
  }
  if (getString) return redirect + path;
  else window.location.href = redirect + path;
}
