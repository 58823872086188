
import { Component, Vue } from "vue-property-decorator";

import VueSlideBar from "vue-slide-bar";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/component_samples/page-header.vue";
import appConfig from "@/app.config.json";

/**
 * Range-slider component
 */
@Component({
  metaInfo: {
    title: "Range Slider",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { VueSlideBar, Layout, PageHeader },
})
class RangeSlider extends Vue {
  public title: string = "Range-Slider";
  items = [
    {
      text: "Veltrix",
      href: "/",
    },
    {
      text: "UI Elements",
      href: "/",
    },
    {
      text: "Range-Slider",
      active: true,
    },
  ];

  public simpleValue: number = 10;
  public sliderCustomzie: number = 300;
  public loader: ReturnType<typeof setInterval> | null = null;
  public loadingValue: number = 0;
  public customStyle: number = 800;
  public lineHeight: number = 10;

  public sliderWithLabel = {
    value: 45,
    data: [15, 30, 45, 60, 75, 90, 120],
    range: [
      {
        label: "15 mins",
      },
      {
        label: "30 mins",
        isHide: true,
      },
      {
        label: "45 mins",
      },
      {
        label: "1 hr",
        isHide: true,
      },
      {
        label: "1 hr 15 mins",
      },
      {
        label: "1 hr 30 mins",
        isHide: true,
      },
      {
        label: "2 hrs",
      },
    ],
    rangeValue: {},
  };

  public customData = {
    value: 3,
    data: [1, 2, 3, 4, 5, 6, 7],
    range: [
      {
        label: "Sunday",
      },
      {
        label: "Monday",
      },
      {
        label: "Tuesday",
      },
      {
        label: "Wednesday",
      },
      {
        label: "Thursday",
      },
      {
        label: "Friday",
      },
      {
        label: "Saturday",
      },
    ],
  };

  /**
   * Range and label slider set range
   */
  callbackRange(val) {
    this.sliderWithLabel.rangeValue = val;
  }
  /**
   * Loading slider
   */
  startLoad() {
    this.loader = setInterval(() => {
      this.loadingValue++;
      if (this.loadingValue === 100) {
        if (this.loader) clearInterval(this.loader);
      }
    }, 100);
  }
}
