
import Vue from 'vue';
/**
 * Transaction component
 */
export default Vue.extend({
  props: {
    transactions: {
      type: Array,
      default: function() {
        return [];
      }
    }
  }
});
