
import { Pie } from "vue-chartjs";
import { Component, Vue, Mixins } from 'vue-property-decorator'
/**
 * Pie chart component
 */
@Component
class PieChart extends Mixins(Vue, Pie) {
  mounted(): void {
    this.renderChart(
      {
        labels: ["Desktops", "Tablets"],
        datasets: [
          {
            data: [300, 180],
            backgroundColor: ["#02a499", "#ebeff2"],
            hoverBackgroundColor: ["#02a499", "#ebeff2"],
            hoverBorderColor: "#fff"
          }
        ]
      },
      {
        maintainAspectRatio: true,
        responsive: true
      }
    );
  }
}

export default PieChart;
