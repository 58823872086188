
import { Component, Vue } from "vue-property-decorator";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/component_samples/page-header.vue";
import appConfig from "@/app.config.json";

/**
 * Modals component
 */
@Component({
  metaInfo: {
    title: "Modals",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
})
class Modals extends Vue {
  title = "Modals";
  items = [
    {
      text: "Veltrix",
      href: "/",
    },
    {
      text: "UI Elements",
      href: "/",
    },
    {
      text: "Modals",
      active: true,
    },
  ];
}

export default Modals;
