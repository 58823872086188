
import { Component, Vue } from "vue-property-decorator";
import vue2Dropzone from "vue2-dropzone";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/component_samples/page-header.vue";
import appConfig from "@/app.config.json";
import { DropzoneOptions } from "dropzone";

/**
 * File Upload component
 */
@Component({
  metaInfo: {
    title: "File Upload",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { vueDropzone: vue2Dropzone, Layout, PageHeader },
})
class FileUpload extends Vue {
  title: string = "File Upload";
  items: any = [
    {
      text: "Veltrix",
      href: "/",
    },
    {
      text: "Forms",
      href: "/",
    },
    {
      text: "File Upload",
      active: true,
    },
  ];

  dropzoneOptions: DropzoneOptions = {
    url: "https://httpbin.org/post",
    thumbnailWidth: 150,
    maxFilesize: 0.5,
    headers: { "My-Awesome-Header": "header value" },
  };
}

export default FileUpload;
