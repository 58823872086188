
import Vue from "vue";
import simplebar from "simplebar-vue";
import { required } from "vuelidate/lib/validators";

/**
 * Chat component
 */
export default Vue.extend({
  components: { simplebar },
  props: {
    title: {
      type: String,
      default: "Chat",
    },
    chatWindowHeight: {
      type: String,
      default: "367px",
    },
    chatMessages: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      chats: {
        message: "",
      },
      submitform: false,
    };
  },
  validations: {
    chats: {
      message: { required },
    },
  },
  methods: {
    /**
     * Chat form submit
     */
    saveMessage() {
      this.submitform = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        var id = this.chatMessages.length;
        const message = this.chats.message;
        const currentDate = new Date();

        // Message Push in Chat
        /*eslint-disable */
        this.chatMessages.push({
          image: require("@/static/assets/images/users/user-3.jpg"),
          id: id + 1,
          name: "Smith",
          message,
          odd: true,
          time: currentDate.getHours() + ":" + currentDate.getMinutes(),
        });
        /*eslint-enable */
      }
      this.submitform = false;
      this.chats = { message: "" };
    },
  },
});
