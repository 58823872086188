
import Vue from 'vue';

import { layoutMethods } from "@/state/helpers";

/**
 * Horizontal navbar component
 */
export default Vue.extend({
  mounted() {
    const links = document.getElementsByClassName("side-nav-link");
    let matchingMenuItem: any = null; 
    
    for (let i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].getAttribute('href')) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      let parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add("active");
        }
        const parent3 = parent2?.parentElement;
        if (parent3) {
          parent3.classList.add("active");
          var childAnchor = parent3.querySelector(".has-dropdown");
          if (childAnchor) childAnchor.classList.add("active");
        }

        const parent4 = parent3?.parentElement;
        if (parent4) parent4.classList.add("active");
        const parent5 = parent4?.parentElement;
        if (parent5) parent5.classList.add("active");
      }
    }
  },
  methods: {
    ...layoutMethods,

    /**
     * menu clicked show the subelement
     */
    onMenuClick(event: { preventDefault: () => void; target: { nextSibling: any; parentNode: any; }; }) {
      event.preventDefault();
      const nextEl = event.target.nextSibling;
      if (nextEl && !nextEl.classList.contains("show")) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove("show");
        }
        nextEl.classList.add("show");
      } else if (nextEl) {
        nextEl.classList.remove("show");
      }
      return false;
    },
    topbarLight() {
      document.body.setAttribute("data-topbar", "light");
      document.body.removeAttribute("data-layout-size");
      // document.body.removeAttribute("data-layout-size", "boxed");
    },
    boxedWidth() {
      document.body.setAttribute("data-layout-size", "boxed");
      // document.body.removeAttribute("data-topbar", "light");
      document.body.removeAttribute("data-topbar");
      document.body.setAttribute("data-topbar", "dark");
    },
    changeLayout(layout: any) {
      this.changeLayoutType({ layoutType: layout });
    }
  }
});
