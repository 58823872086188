<script>
import Vue from 'vue';
import { copyright } from "@/middleware/helpers";
export default Vue.extend({
  data() {
    return {
      copy: copyright,
    };
  }
});
</script>

<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12" style="text-transform: capitalize">
          © {{ copy }}
        </div>
      </div>
    </div>
  </footer>
  <!-- end footer -->
</template>