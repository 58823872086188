
import Vue from "vue";
import router from "@/router";

import Topbar from "@/component_samples/topbar.vue";
import Sidebar from "@/component_samples/sidebar.vue";
import Footer from "@/component_samples/footer.vue";
import RightBar from "@/component_samples/right-bar.vue";

export default Vue.extend({
  components: {
    Topbar,
    Sidebar,
    Footer,
    RightBar,
  },
  created() {
    document.body.setAttribute("data-sidebar", "light");
    document.body.removeAttribute("data-topbar");
    document.body.removeAttribute("data-layout-size");
    document.body.removeAttribute("data-layout");
  },
  methods: {
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");
      document.body.classList.toggle("vertical-collpsed");

      if (window.screen.width <= 768) {
        document.body.classList.remove("vertical-collpsed");
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
      }
    },
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
  },
});
