
import { Component, Vue } from "vue-property-decorator";
import Swal from "sweetalert2";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/component_samples/page-header.vue";
import appConfig from "@/app.config.json";

/**
 * SweetAlert 2 component
 */
@Component({
  metaInfo: {
    title: "SweetAlert 2",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
})
class SweetAlert2 extends Vue {
  title = "SweetAlert 2";
  items = [
    {
      text: "Veltrix",
      href: "/",
    },
    {
      text: "UI Elements",
      href: "/",
    },
    {
      text: "SweetAlert 2",
      active: true,
    },
  ];

  showAlert() {
    Vue.swal("Any fool can use a computer!");
  }

  titleText() {
    Swal.fire("The Internet?", "That thing is still around?", "question");
  }

  successmsg() {
    Swal.fire("Good job!", "You clicked the button!", "success");
  }

  confirm() {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34c38f",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  }

  cancel() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger ml-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        showCancelButton: true,
      })
      .then((result) => {
        if (result.value) {
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "Your file has been deleted.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Your imaginary file is safe :)",
            "error"
          );
        }
      });
  }

  custom() {
    Vue.swal({
      title: "Sweet!",
      text: "Modal with a custom image.",
      imageUrl: require("@/static/assets/images/logo-dark.png"),
      imageHeight: 20,
      confirmButtonColor: "#556ee6",
    });
  }

  timer() {
    let timerInterval;
    Swal.fire({
      title: "Auto close alert!",
      html: "I will close in <b></b> milliseconds.",
      timer: 2000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const timerLeft = Swal.getTimerLeft();
          // @ts-ignore
          Swal.getContent().querySelector("b").textContent = timerLeft
            ? timerLeft.toString()
            : "";
        }, 100);
      },
      onClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.timer
      ) {
        console.log("I was closed by the timer"); // eslint-disable-line
      }
    });
  }

  customHtml() {
    Swal.fire({
      title: "<i>HTML</i> <u>example</u>",
      icon: "info",
      html:
        "You can use <b>bold text</b>, " +
        '<a href="//sweetalert2.github.io">links</a> ' +
        "and other HTML tags",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#47bd9a",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!',
      confirmButtonAriaLabel: "Thumbs up, great!",
      cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
      cancelButtonAriaLabel: "Thumbs down",
    });
  }

  customBackground() {
    Swal.fire({
      title: "Custom width, padding, background.",
      width: 600,
      padding: 100,
      confirmButtonColor: "#556ee6",
      background:
        "#fff url(//subtlepatterns2015.subtlepatterns.netdna-cdn.com/patterns/geometry.png)",
    });
  }

  ajax() {
    Swal.fire({
      title: "Submit email to run ajax request",
      input: "email",
      showCancelButton: true,
      confirmButtonText: "Submit",
      showLoaderOnConfirm: true,
      confirmButtonColor: "#556ee6",
      cancelButtonColor: "#f46a6a",
      preConfirm: (email) => {
        // eslint-disable-next-line no-unused-vars
        return new Promise(function (resolve, reject) {
          setTimeout(function () {
            if (email === "taken@example.com") {
              Promise.reject(new Error("This email is already taken."));
            } else {
              resolve(null);
            }
          }, 2000);
        });
      },
      allowOutsideClick: false,
    }).then((email) => {
      Swal.fire({
        icon: "success",
        title: "Ajax request finished!",
        html: "Submitted email: " + email,
      });
    });
  }

  chain() {
    Swal.mixin({
      input: "text",
      confirmButtonText: "Next &rarr;",
      showCancelButton: true,
      confirmButtonColor: "#556ee6",
      cancelButtonColor: "#74788d",
      progressSteps: ["1", "2", "3"],
    })
      .queue([
        {
          title: "Question 1",
          text: "Chaining swal2 modals is easy",
        },
        "Question 2",
        "Question 3",
      ])
      .then((result: any) => {
        if (result.value) {
          Swal.fire({
            title: "All done!",
            html:
              "Your answers: <pre><code>" +
              JSON.stringify(result.value) +
              "</code></pre>",
            confirmButtonText: "Lovely!",
          });
        }
      });
  }

  dynamicQueue() {
    var ipAPI = "https://api.ipify.org?format=json";
    Swal.queue([
      {
        title: "Your public IP",
        confirmButtonText: "Show my public IP",
        text: "Your public IP will be received " + "via AJAX request",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch(ipAPI)
            .then((response) => response.json())
            .then((data) => Swal.insertQueueStep(data.ip))
            .catch(() => {
              Swal.insertQueueStep({
                // @ts-ignore
                type: "error",
                title: "Unable to get your public IP",
              });
            });
        },
      },
    ]);
  }
}

export default SweetAlert2;
