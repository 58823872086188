
import { Component, Vue, Mixins } from 'vue-property-decorator';
import { Doughnut } from "vue-chartjs";

/**
 * Donut-chart
 */
@Component
class DoughnutChart extends Mixins(Vue, Doughnut) {
  mounted(): void {
    this.renderChart(
      {
        labels: ["Desktops", "Tablets"],
        datasets: [
          {
            data: [300, 210],
            backgroundColor: ["#3c4ccf", "#ebeff2"],
            hoverBackgroundColor: ["#3c4ccf", "#ebeff2"],
            hoverBorderColor: "#fff"
          }
        ]
      },
      {
        maintainAspectRatio: true
      }
    );
  }
}

export default DoughnutChart;
