import { Component, Vue } from "nuxt-property-decorator";

@Component
export default class CopyToClipboard extends Vue {
  downloadCSV(filename: string, rows: Object[], filter: string) {
    const csvFile =
      rows // header
        .slice(0, 1)
        .map(function (d) {
          return JSON.stringify(Object.keys(d));
        })[0]
        .replace(/(^\[)|(\]$)/gm, "") +
      "\n" +
      rows // data
        .filter((ob) =>
          JSON.stringify(Object.values(ob))
            .toLowerCase()
            .includes(filter.toLowerCase())
        )
        .slice(0, 10000)
        .map(function (d: any) {
          return JSON.stringify(Object.values(d));
        })
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

    const blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename + ".csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}
