























































































import { Component, Vue } from "vue-property-decorator";

import Layout from "@/router/layouts/main.vue";
import appConfig from "@/app.config.json";

import { addDays, format } from "date-fns";
import distributionResultsQuery from "@/queries/distributionResults.gql";

@Component({
  metaInfo: {
    title: "Distribution Info",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
  },
})
class DistributionResults extends Vue {
  isFetching = true;
  selectedDate = "";
  distributionStartDate: Date | undefined;
  distributionEndDate: Date | undefined;
  distributionData: any = null;

  get distributionDate(): Date {
    if (this.selectedDate !== "") {
      return new Date(this.selectedDate + "T00:00:00");
    }
    return new Date();
  }

  get formattedDate(): string {
    this.getDistributionResults().catch();
    return format(this.distributionDate, "MMMM d");
  }

  async created(): Promise<void> {
    await this.getDistributionResults().catch();
  }

  setDate() {
    this.$root.$emit("bv::hide::modal", "calendar-modal", "#show");
  }

  openCalendar(): void {
    this.$root.$emit("bv::show::modal", "calendar-modal", "#show");
  }

  closeCalendar(): void {
    this.$root.$emit("bv::hide::modal", "calendar-modal", "#show");
  }

  getIsPreviousOrNextDayValid(daysToAdd: number): boolean {
    const newDate = addDays(this.distributionDate, daysToAdd);
    return true;
    // return (
    //   newDate > (this.distributionStartDate as Date) &&
    //   newDate < (this.distributionEndDate as Date)
    // );
  }

  getPreviousOrNextDayLink(daysToAdd: number): void {
    const newDate = addDays(this.distributionDate, daysToAdd);
    this.selectedDate = newDate.toJSON().split("T")[0];
  }

  async getDistributionResults(): Promise<void> {
    this.isFetching = true;
    const response = await this.$apollo.query({
      query: distributionResultsQuery,
      variables: { date: this.distributionDate },
    });

    this.distributionData = {
      globalResults: response.data.distributionInfo,
    };
    this.isFetching = false;
  }
}

export default DistributionResults;
