
/**
 * Page-header component
 */

import Vue from "vue"
export default Vue.extend({
  components: {},
  props: {
    title: {
      type: String,
      default: ""
    },
    items: {
      type: Array,
      default: () => {
        return [];
      }
    }
  }
})

