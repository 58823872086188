import { MutationTree, ActionTree, GetterTree } from "vuex";
import { EFetchStatus } from "../types";
// import { GraphQLEnumType } from "graphql";

export interface ICartAddress {
  coinSymbol: string;
  address: string;
  qrCode: string;
}
export interface ICartAddressRequest {
  userId: string;
  coinSymbol: string;
  // amountUsd: string;
  // amountCrypto: string;
  orderId: string;
  affiliateId: string;
  affiliateSessionId: string;
  utmVariables: string;
  created: Date;
  addresses: ICartAddress[];
}
export interface ICartTransaction {
  wp_id: string;
  status: string;
  currency: string;
  name: string;
  email: string;
  totalUsd: string;
  totalCrypto: string;
  totalCryptoReceived: string;
  conversionRate: string;
  remainingCrypto: string;
  address: string;
  created: Date;
  discountAmtUsd: string;
  data: string;
  productName: string;
  quantity: number;
}

export interface ErrorResponse {
  message: string;
  code: string;
  stack?: string;
}

export interface ISalesState {
  transactions: ICartTransaction[];
  requests: ICartAddressRequest[];
  statusTransactions: EFetchStatus;
  statusRequests: EFetchStatus;
}

export const state: ISalesState = {
  transactions: [],
  requests: [],
  statusTransactions: EFetchStatus.nostate,
  statusRequests: EFetchStatus.nostate,
};

export const mutations: MutationTree<ISalesState> = {
  setTransactions(state, data: ICartTransaction) {
    Object.assign(state.transactions, data);
    state.statusTransactions = EFetchStatus.success;
  },
  setRequests(state, data: ICartTransaction) {
    Object.assign(state.requests, data);
    state.statusRequests = EFetchStatus.success;
  },
  setFetchTransactions(state, data: EFetchStatus) {
    state.statusTransactions = data;
  },
  setFetchRequests(state, data: EFetchStatus) {
    state.statusRequests = data;
  },
};

export const getters: GetterTree<ISalesState, {}> = {
  getTransactions(state) {
    return state.transactions;
  },
  getRequests(state) {
    return state.requests;
  },
  checkTransactions(state) {
    return state.statusTransactions;
  },
  checkRequests(state) {
    return state.statusRequests;
  },
};

import getTransactions from "@/queries/allCartTransactions.gql";
import getAddresses from "@/queries/allCartAddresses.gql";
import { apolloClient } from "@/apolloClient";
export const actions: ActionTree<ISalesState, {}> = {
  async getTransactionData({ commit }: any) {
    try {
      commit("setFetchTransactions", EFetchStatus.fetching);
      const client = apolloClient;
      const {
        data: { getAllCartTransactions },
      } = await client.query({
        query: getTransactions,
        fetchPolicy: "network-only",
      });
      commit("setTransactions", getAllCartTransactions);
    } catch (error: any) {
      commit("setFetchTransactions", EFetchStatus.failed);
      commit("updateSnackbarErrorText", error.message, { root: true });
    }
  },
  async getAddressData({ commit }: any) {
    try {
      commit("setFetchRequests", EFetchStatus.fetching);
      const client = apolloClient;
      const {
        data: { getAllCartAddressRequests },
      } = await client.query({
        query: getAddresses,
        fetchPolicy: "network-only",
      });
      commit("setRequests", getAllCartAddressRequests);
    } catch (error: any) {
      commit("setFetchRequests", EFetchStatus.failed);
      commit("updateSnackbarErrorText", error.message, { root: true });
    }
  },
};
