
import Layout from "../../layouts/main.vue";
import PageHeader from "@/component_samples/page-header.vue";
import appConfig from "@/app.config.json";

import { emailData } from "./data-inbox.ts";

import Vue from 'vue'
/**
 * Email-inbox component
 */
export default Vue.extend({
  metaInfo: {
    title: "Inbox",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      emailData: emailData,
      paginatedEmailData: emailData,
      title: "Inbox",
      items: [
        {
          text: "Veltrix",
          href: "/"
        },
        {
          text: "Email",
          href: "/"
        },
        {
          text: "Inbox",
          active: true
        }
      ],
      // page number
      currentPage: 1,
      // default page size
      perPage: 15,

      // start and end index
      startIndex: 1,
      endIndex: 15
    };
  },
  computed: {
    /**
     * Return emaildata length
     */
    rows() {
      // return this.emailData.length;
      const emailDataLength: number = this.emailData.length;
      return emailDataLength;
    }
  },
  created() {
    this.startIndex = 0;
    this.endIndex = this.perPage;

    this.paginatedEmailData = this.emailData.slice(
      this.startIndex,
      this.endIndex
    );
  },
  methods: {
    /**
     * Pagination page change method
     */
    onPageChange() {
      this.startIndex = (this.currentPage - 1) * this.perPage;
      this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;

      this.paginatedEmailData = this.emailData.slice(
        this.startIndex,
        this.endIndex
      );
    }
  }
})
