
import Vue from  'vue';
/**
 * Activity component
 */
export default Vue.extend({
  props: {
    title: {
      type: String,
      default: "Activity"
    },
    activityData: {
      type: Array,
      default: function() {
        return [];
      }
    }
  }
});
