
import { Component, Vue } from "vue-property-decorator";

import appConfig from "@/app.config.json";

@Component({
  metaInfo: {
    title: "500",
    meta: [{ name: "description", content: appConfig.description }],
  },
})
class Page500 extends Vue {}

export default Page500;
