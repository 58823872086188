import ClientAuth from '@blockbrothers/firebasebb/dist/src/Client';
import Vue from 'vue';
import { DollarApollo } from 'vue-apollo/types/vue-apollo';
import { ApolloError } from 'apollo-client';
import login from '@/mutations/login.gql';
import { EventBus } from './eventBus';
import { brandName, BRAND_TOKEN } from '@/middleware/helpers';

const brand = brandName;

export const auth = new ClientAuth({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY || '',
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN || '',
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID || '',
});

export function getCookie(cname: string): string {
  const name = cname.toLowerCase() + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let c of ca) {
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function setCookie(token: string): void {
  if (process.browser) {
    const { hostname } = window.location;

    const domain =
      hostname === 'localhost'
        ? 'localhost'
        : hostname.split('.').slice(-2).join('.');
    const now = new Date();
    let time = now.getTime();
    time += 3600 * 1000;
    now.setTime(time);
    const expiration = now.toUTCString();
    document.cookie = `${BRAND_TOKEN}=${token}; domain=${domain}; expires=${expiration};`;
  }
}

export function isLoggedIn(): any {
  let token;
  if (process.browser) {
    token = getCookie(BRAND_TOKEN);
    if (!token) {
      return false;
    }
    const authenticated = auth.isAuthenticated(token);
    const authorized = auth.verifyTwoFaAuthenticated(token);
    return authorized && authenticated;
  }
}

export function requireAuth(to, from, next) {
  if (!isLoggedIn()) {
    return false;
  } else {
    return true;
  }
}

export function getPermissions(): any {
  const token = getCookie(BRAND_TOKEN);
  const permissions = auth.getPermissions(token);
  return permissions;
}

export function logout(): void {
  if (process.browser) {
    const { hostname } = window.location;
    const domain =
      hostname === 'localhost'
        ? 'localhost'
        : hostname.split('.').slice(-2).join('.');
    document.cookie = `${BRAND_TOKEN}=; domain=${domain}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  }
  auth.signOut();
}

export async function signInCookie(
  cookieName: string,
  apollo: DollarApollo<Vue>
): Promise<any> {
  try {
    // if (brand !== 'connect') {
    //     return {};
    // }

    // This function should be implemented hand-in-hand with the server.
    const fbToken = getCookie(cookieName);

    if (fbToken) {
      const {
        data: {
          login: loginRes,
          login: { token },
        },
        errors,
      } = await apollo.mutate({
        mutation: login,
        variables: {
          token: fbToken,
        },
      });

      if (!token) {
        let error = '';
        errors?.forEach((a) => (error += a.message));
        throw new Error(error);
      }
      setCookie(token);
      return loginRes;
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
  return {};
}

export async function signIn(
  email: string,
  password: string,
  apollo: DollarApollo<Vue>
): Promise<any> {
  try {
    // This function should be implemented hand-in-hand with the server.
    const fbToken = await auth.signIn(email, password);
    const {
      data: {
        login: loginRes,
        login: { token },
      },
      errors,
    } = await apollo.mutate({
      mutation: login,
      variables: {
        token: fbToken,
      },
    });
    if (!token) {
      let error = '';
      errors?.forEach((a) => (error += a.message));
      throw new Error(error);
    }
    setCookie(token);
    return loginRes;
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export async function requestPasswordResetEmail(email: string): Promise<any> {
  try {
    const emailSent = await auth.requestPasswordResetEmail(email);
    return emailSent;
  } catch (error: any) {
    throw new Error(error);
  }
}

export function handleAuthError(error: ApolloError): void {
  const { graphQLErrors } = error;
  if (graphQLErrors) {
    if (graphQLErrors) {
      const authenticationErrors = graphQLErrors.filter((err: Error) =>
        err.message.includes('AuthenticationError:')
      );
      if (authenticationErrors.length) {
        EventBus.$emit('force-logout');
      }
    }
  }
}

export function forceLogout(): void {
  EventBus.$emit('force-logout');
}
