
import { Component, Vue } from "vue-property-decorator";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/component_samples/page-header.vue";
import appConfig from "@/app.config.json";

import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
} from "vuelidate/lib/validators";

/**
 * Form Validation component
 */
@Component({
  metaInfo: {
    title: "Form Validation",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  validations: {
    range: {
      minlen: { required, minLength: minLength(6) },
      maxlength: { required, maxLength: maxLength(6) },
      between: { required, minLength: minLength(5), maxLength: maxLength(10) },
      minval: { required, minValue: minValue(6) },
      maxval: { required, maxValue: maxValue(6) },
      rangeval: { required, minValue: minValue(6), maxValue: maxValue(100) },
      expr: { required },
    },
    typeform: {
      name: { required },
      password: { required, minLength: minLength(6) },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
      email: { required, email },
      url: { required, url },
      digit: { required, numeric },
      number: { required, numeric },
      alphanum: { required, alphaNum },
      textarea: { required },
    },
  },
})
class FormValidation extends Vue {
  title: string = "Form Validation";
  items = [
    {
      text: "Veltrix",
      href: "/",
    },
    {
      text: "Forms",
      href: "/",
    },
    {
      text: "Form Validation",
      active: true,
    },
  ];
  range = {
    minlen: "",
    maxlength: "",
    between: "",
    minval: "",
    maxval: "",
    rangeval: "",
    expr: "",
  };
  typeform = {
    name: "",
    password: "",
    confirmPassword: "",
    email: "",
    url: "",
    digit: "",
    number: "",
    alphanum: "",
    textarea: "",
  };
  submit = false;
  typesubmit = false;

  /**
   * Range validation form submit
   */
  // eslint-disable-next-line no-unused-vars
  rangeform(e) {
    this.submit = true;
    // stop here if form is invalid
    this.$v.$touch();
  }

  /**
   * Validation type submit
   */
  // eslint-disable-next-line no-unused-vars
  typeForm(e) {
    this.typesubmit = true;
    // stop here if form is invalid
    this.$v.$touch();
  }
}

export default FormValidation;
