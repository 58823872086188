
import { Component, Vue } from "vue-property-decorator";

import appConfig from "@/app.config.json";

/**
 * Recover password-2 component
 */
@Component({
  metaInfo: {
    title: "Recover password-2",
    meta: [{ name: "description", content: appConfig.description }],
  },
})
class RecoverPassword2 extends Vue {}

export default RecoverPassword2;
