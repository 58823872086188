// import firebase from 'firebase/app';
// Add the Firebase products that you want to use
// import "firebase/auth";
// import "firebase/firestore";
import { auth } from '@/middleware/auth';
// import auth from '@blockbrothers/firebasebb/dist/src/Client';

class FirebaseAuthBackend {
    constructor(){
        auth.fireAuth.onAuthStateChanged((user) => {
            if (user) {
                sessionStorage.setItem("authUser", JSON.stringify(user));
            } else {
                sessionStorage.removeItem('authUser');
            }
        });
    }

    /**
     * Registers the user with given details
     */
    registerUser = (email: string, password: string) => {
        return new Promise((resolve, reject) => {
            auth.fireAuth.createUserWithEmailAndPassword(email, password).then(() => {
                // eslint-disable-next-line no-redeclare
                // var user = auth.fireAuth.currentUser;
                // resolve(user);
                resolve(auth.fireAuth.currentUser)
            }, (error) => {
                reject(this._handleError(error));
            });
        });
    }

    /**
     * Login user with given details
     */
    loginUser = (email: string, password: string) => {
        return new Promise((resolve, reject) => {
            auth.fireAuth.signInWithEmailAndPassword(email, password).then(() => {
                // eslint-disable-next-line no-redeclare
                // var user = auth.fireAuth.currentUser;
                // resolve(user);
                resolve(auth.fireAuth.currentUser)
            }, (error) => {
                reject(this._handleError(error));
            });
        });
    }

    /**
     * forget Password user with given details
     */
    forgetPassword = (email: string): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            auth.fireAuth.sendPasswordResetEmail(email, { url: window.location.protocol + "//" + window.location.host + "/login" }).then(() => {
                resolve(true);
            }).catch((error) => {
                reject(this._handleError(error));
            })
        });
    }

    /**
     * Logout the user
     */
    logout = () => {
        return new Promise((resolve, reject) => {
            auth.fireAuth.signOut().then(() => {
                resolve(true);
            }).catch((error) => {
                reject(this._handleError(error));
            })
        });
    }

    setLoggeedInUser = (user: any) => {
        sessionStorage.setItem("authUser", JSON.stringify(user));
    }

    /**
     * Returns the authenticated user
     */
    getAuthenticatedUser = () => {
        const user = sessionStorage.getItem('authUser');
        if(!user)
            return null;
        return JSON.parse(user)
        // if (!sessionStorage.getItem('authUser'))
        //     return null;
        // return JSON.parse(sessionStorage.getItem('authUser'));
    }

    /**
     * Handle the error
     * @param {*} error 
     */
    _handleError(error: { message: any }) {
        const errorMessage = error.message;
        return errorMessage;
    }
}

let _fireBaseBackend: FirebaseAuthBackend = new FirebaseAuthBackend();

/**
 * Initilize the backend
 * @param {*} config 
 */
const initFirebaseBackend = (config: {
    apiKey: any; authDomain: any; databaseURL: any; projectId: any; storageBucket: any; messagingSenderId: any; appId: any; measurementId?: any;
}) => {
    if (!_fireBaseBackend) {
        _fireBaseBackend = new FirebaseAuthBackend();
    }
    return _fireBaseBackend;
}

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = (): FirebaseAuthBackend => {
    return _fireBaseBackend;
}

export { initFirebaseBackend, getFirebaseBackend };