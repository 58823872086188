import Vue from "vue";
import App from "./App.vue";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import Vuelidate from "vuelidate";
import VueMask from "v-mask";
import * as VueGoogleMaps from "vue2-google-maps";
import VueSweetalert2 from "vue-sweetalert2";
import VueApexCharts from "vue-apexcharts";
import router from "@/router/index";
import store from "@/state/store.ts";
// import vco from "v-click-outside"
import VueApollo from "vue-apollo";
import "@/static/design/index.scss";
import Sparkline from "vue-sparklines";
import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";

// import { initFirebaseBackend } from './authUtils';
import { apolloClient } from "./apolloClient";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: "/",
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_storageBucket,
  messagingSenderId: process.env.VUE_APP_messagingSenderId,
  appId: process.env.VUE_APP_BRAND,
  measurementId: process.env.VUE_APP_measurementId,
  sentryDsn: process.env.VUE_APP_SENTRY_DSN,
  isProd: process.env.NODE_ENV === 'production'
};

//initFirebaseBackend(firebaseConfig);

// initFirebaseBackend(firebaseConfig);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.use(VueApollo);

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(VueMask);
Vue.use(VueSweetalert2);
Vue.use(require("vue-chartist"));
// Vue.use(vco)
Vue.use(Sparkline);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    libraries: "places",
  },
  installComponents: true,
});
Vue.component("Apexchart", VueApexCharts);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

Vue.config.devtools = true;
// @ts-ignore
Vue.config.debug = true;

new Vue({
  router,
  store,
  apolloProvider,
  render: (h) => h(App),
}).$mount("#app");
