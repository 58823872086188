
const date = new Date();
const d = date.getDate();
const m = date.getMonth();
const y = date.getFullYear();

export const calendarEvents: CalendarEvent[] = [
    {
        title: 'All Day Event',
        start: new Date(y, m, 1),
        className: 'bg-primary',
    },
    {
        title: 'Long Event',
        start: new Date(y, m, d - 5),
        end: new Date(y, m, d - 2),
        className: 'bg-primary',
    },
    {
        id: 999,
        title: 'Repeating Event',
        start: new Date(y, m, d - 3, 16, 0),
        allDay: false,
        className: 'bg-primary',
    },
    {
        id: 999,
        title: 'Repeating Event',
        start: new Date(y, m, d + 4, 16, 0),
        allDay: false,
        className: 'bg-primary',
    },
    {
        title: 'Meeting',
        start: new Date(y, m, d, 10, 30),
        allDay: false,
        className: 'bg-primary',
    },
    {
        title: 'Lunch',
        start: new Date(y, m, d, 12, 0),
        end: new Date(y, m, d, 14, 0),
        allDay: false,
        className: 'bg-primary',
    },
    {
        title: 'Birthday Party',
        start: new Date(y, m, d + 1, 19, 0),
        end: new Date(y, m, d + 1, 22, 30),
        allDay: false,
        className: 'bg-primary',
    },
    {
        title: 'Click for Google',
        start: new Date(y, m, 28),
        end: new Date(y, m, 29),
        url: 'http://google.com/',
        className: 'bg-primary',
    }
];

export interface CalendarEvent {
    id?: number;
    title: string;
    start: Date;
    end?: Date;
    allDay?: boolean;
    className?: string;
    url?: string;
    backgroundColor?: string;
}