
import { Component, Vue } from "vue-property-decorator";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/component_samples/page-header.vue";
import appConfig from "@/app.config.json";

/**
 * Colors component
 */
@Component({
  metaInfo: {
    title: "Colors",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
})
class Colors extends Vue {
  data() {
    return {
      title: "Colors",
      items: [
        {
          text: "Veltrix",
          href: "/",
        },
        {
          text: "UI Elements",
          href: "/",
        },
        {
          text: "Colors",
          active: true,
        },
      ],
    };
  }
}

export default Colors;
