
import { Component, Vue } from "vue-property-decorator";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/component_samples/page-header.vue";
import appConfig from "@/app.config.json";

/**
 * Basic-table component
 */
@Component({
  metaInfo: {
    title: "Basic table",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
})
class BasicTable extends Vue {
  title = "Basic Tables";
  items = [
    {
      text: "Veltrix",
      href: "/",
    },
    {
      text: "Tables",
      href: "/",
    },
    {
      text: "Basic",
      active: true,
    },
  ];
}

export default BasicTable;
