import Vue from 'vue';
import Vuex, { StoreOptions, Store } from 'vuex';

import modules from './modules';
import { IRootState } from './types';

Vue.use(Vuex);

const store: StoreOptions<IRootState> = {
  state: {
    version: '1.0.0',
    showSuccessSnackbar: false,
    showErrorSnackbar: false,
    showWarningSnackbar: false,
    snackbarErrorText: 'Something went wrong.',
    snackbarWarningText: 'Uh Oh...',
    snackbarSuccessText: 'Success.',
    currentPage: { title: '', showBoxCoinBalance: false },
    previousPage: { path: '/', sameAsCurrent: true },
  },
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
  mutations: {
    toggleSuccessSnackbar(rootState: IRootState, payload?: boolean) {
      rootState.showSuccessSnackbar =
        payload === undefined || payload === null
          ? !rootState.showSuccessSnackbar
          : payload;
    },
    toggleWarningSnackbar(rootState: IRootState, payload?: boolean) {
      rootState.showWarningSnackbar =
        payload === undefined || payload === null
          ? !rootState.showWarningSnackbar
          : payload;
    },
    toggleErrorSnackbar(rootState: IRootState, payload?: boolean) {
      rootState.showErrorSnackbar =
        payload === undefined || payload === null
          ? !rootState.showErrorSnackbar
          : payload;
    },
    updateSnackbarSuccessText(rootState: IRootState, payload: string) {
      rootState.snackbarSuccessText = payload;
    },
    updateSnackbarWarningText(rootState: IRootState, payload: string) {
      rootState.snackbarWarningText = payload;
    },
    updateSnackbarErrorText(rootState: IRootState, payload: string) {
      rootState.snackbarErrorText = payload;
    },
  },
};

export default (): Store<IRootState> => new Store<IRootState>(store);
