
import { Component, Vue } from "vue-property-decorator";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/component_samples/page-header.vue";
import appConfig from "@/app.config.json";

/**
 * Google-map component
 */
@Component({
  metaInfo: {
    title: "Google Map",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
})
class GoogleMap extends Vue {
  public title = "Google Maps";
  public items = [
    {
      text: "Veltrix",
      href: "/",
    },
    {
      text: "Maps",
      href: "/",
    },
    {
      text: "Google Maps",
      active: true,
    },
  ];

  public markers: Marker[] = [
    {
      position: { lat: 10.0, lng: 10.0 },
    },
    {
      position: { lat: 15.0, lng: 13.0 },
    },
  ];

  public pov = null;
  public pano = null;
  public edited: Array<Coordinates[]> | null = null;
  public paths: Array<Coordinates[]> = [
    [
      { lat: 1.38, lng: 103.8 },
      { lat: 1.38, lng: 103.81 },
      { lat: 1.39, lng: 103.81 },
      { lat: 1.39, lng: 103.8 },
    ],
    [
      { lat: 1.382, lng: 103.802 },
      { lat: 1.382, lng: 103.808 },
      { lat: 1.388, lng: 103.808 },
      { lat: 1.388, lng: 103.802 },
    ],
  ];

  /**
   * street view data update
   */
  updatePov(pov) {
    this.pov = pov;
  }
  /**
   * street view data update
   */
  updatePano(pano) {
    this.pano = pano;
  }
  /**
   * polygon editing
   */
  updateEdited(mvcArray) {
    let paths: Array<Coordinates[]> = [];
    for (let i = 0; i < mvcArray.getLength(); i++) {
      let path: Coordinates[] = [];
      for (let j = 0; j < mvcArray.getAt(i).getLength(); j++) {
        let point = mvcArray.getAt(i).getAt(j);
        path.push({ lat: point.lat(), lng: point.lng() });
      }
      paths.push(path);
    }
    this.edited = paths;
  }
}

type Coordinates = {
  lat: string | number;
  lng: string | number;
};

interface Marker {
  position: Coordinates;
}

export default GoogleMap;
