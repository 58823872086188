
import Vue from 'vue';
import { layoutComputed } from "@/state/helpers";

import HorizontalTopbar from "@/component_samples/horizontal-topbar.vue";
import HorizontalNav from "@/component_samples/horizontal-nav.vue";
import Footer from "@/component_samples/footer.vue";
import RightBar from "@/component_samples/right-bar.vue";

/**
 * Horizontal-layout component
 */
export default Vue.extend({
  components: { HorizontalTopbar, HorizontalNav, RightBar, Footer },
  computed: {
    ...layoutComputed
  },
  created: () => {
    document.body.setAttribute("data-layout", "horizontal");
    document.body.setAttribute("data-topbar", "dark");
    document.body.removeAttribute("data-sidebar");
    document.body.classList.remove("vertical-collpsed");
    document.body.removeAttribute('data-layout-size');
    document.body.removeAttribute('data-sidebar-size');
  },
  methods: {
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    }
  }
});
