
import { Component, Vue } from "vue-property-decorator";

import StarRating from "vue-star-rating";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/component_samples/page-header.vue";
import appConfig from "@/app.config.json";

/**
 * Rating component
 */
@Component({
  metaInfo: {
    title: "Rating",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { StarRating, Layout, PageHeader },
})
class Ratings extends Vue {
  title = "Rating";
  items = [
    {
      text: "Veltrix",
      href: "/",
    },
    {
      text: "UI Elements",
      href: "/",
    },
    {
      text: "Rating",
      active: true,
    },
  ];
}

export default Ratings;
