import { MutationTree, ActionTree, GetterTree } from 'vuex';

interface IUserIds {
    [brandUserId: string]: string | undefined;
    connectUserId?: string;
    greenUserId?: string;
    codexUserId?: string;
    arcadeUserId?: string;
    blueUserId?: string;
  }
  
interface IUser {
    preferences: { currency: string; language: string; notifications: boolean };
    email: string;
    secondaryEmail?: string;
    phone: string;
    firstName: string;
    lastName: string;
    displayName?: string;
    role: string;
    permissions: string[];
    walletPassRequired: boolean;
    walletExists: boolean;
    twoFaAuthenticated: boolean;
    twoFaEnabled: boolean;
    loggedIn: boolean;
    id: string;
    profilePhotoUrl: string | null;
    gameToken: string;
    number: string;
    userIds?: IUserIds;
  }
  
 interface IProfileState {
    user: IUser;
    activated: boolean | undefined;
    btcBalanceConfirmed: string;
    btcBalancePending: string;
    shareUrl: string;
    error: boolean;
    btcUsdPrice: string | undefined;
    showWinIdDialog: boolean;
  }
  
const initialUserState = {
    preferences: {
      currency: 'USD',
      language: 'en',
      notifications: true,
    },
    profilePhotoUrl: '',
    id: '',
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    displayName: '',
    role: '',
    permissions: [],
    walletPassRequired: false,
    walletExists: false,
    twoFaAuthenticated: false,
    twoFaEnabled: false,
    loggedIn: false,
    gameToken: '',
    number: '',
  };

export const state: IProfileState  = {
    user: initialUserState,
    btcUsdPrice: undefined,
    activated: false,
    btcBalanceConfirmed: '',
    btcBalancePending: '',
    shareUrl: '',
    error: false,
    showWinIdDialog: false,
}

export const mutations: MutationTree<IProfileState> = {
    updateUser(state, userInfo: IUser) {
        userInfo.loggedIn = true;
    
        Object.assign(state.user, userInfo);
      },
}

export const getters: GetterTree<IProfileState, {}> = {
  getUser(state) {
    return state.user;
  },
  isInternal(state) {
    return state.user.permissions.indexOf('INTERNAL_EMPLOYEE') !== -1 ? true : false
  }
}

import getUser from '@/queries/getUser.gql';
import { apolloClient } from '@/apolloClient';
import { getPermissions } from '@/middleware/auth';
export const actions: ActionTree<IProfileState, {}> = {
    clearUser({ commit }: any) {
        commit('clearUser');
      },
      async getUserData({ commit }: any) {
        try {
          const client = apolloClient;
          const rawData = await client.query({
            query: getUser,
          });
          const user = {
            ...rawData.data.profile,
          };
          if (user.language) {
            Object.assign(user, { preferences: { language: user.language } });
          }
          // ensure to populate permissions in state
          const permissions = getPermissions();
          if (permissions) {
            Object.assign(user, { permissions });
          }
          commit('updateUser', user);
        } catch (error: any) {
          console.log(error);
        }
      },
}
