
import Vue from "vue";

import Layout from "@/router_samples/layouts/main.vue";
import appConfig from "@/app.config.json";

import Chart from "@/component_samples/widgets/chart.vue";
import Stat from "@/component_samples/widgets/widget-stat.vue";
import Transaction from "@/component_samples/widgets/transaction.vue";
import Chat from "@/component_samples/widgets/chat.vue";
import Activity from "@/component_samples/widgets/activity.vue";

import { sparklineChartData, salesDonutChart, radialBarChart } from "./data";

export default Vue.extend({
  metaInfo: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    Chart,
    Stat,
    Transaction,
    Chat,
    Activity,
  },
  data() {
    return {
      sparklineChartData: sparklineChartData,
      salesDonutChart: salesDonutChart,
      radialBarChart: radialBarChart,
      statData: [
        {
          title: "Orders",
          image: require("@/static/assets/images/services-icon/01.png"),
          value: "1,685",
          subText: "+ 12%",
          color: "success",
        },
        {
          title: "Revenue",
          image: require("@/static/assets/images/services-icon/02.png"),
          value: "52,368",
          subText: "- 28%",
          color: "danger",
        },
        {
          title: "Average Price",
          image: require("@/static/assets/images/services-icon/03.png"),
          value: "15.8",
          subText: "00%",
          color: "info",
        },
        {
          title: "Product Sold",
          image: require("@/static/assets/images/services-icon/04.png"),
          value: "2436",
          subText: "+ 84%",
          color: "warning",
        },
      ],
      transactions: [
        {
          id: "#14256",
          image: require("@/static/assets/images/users/user-2.jpg"),
          name: "Philip Smead",
          date: "15/1/2018",
          amount: "$94",
          status: "Delivered",
        },
        {
          id: "#14257",
          image: require("@/static/assets/images/users/user-3.jpg"),
          name: "Brent Shipley",
          date: "16/1/2019",
          amount: "$112",
          status: "Pending",
        },
        {
          id: "#14258",
          image: require("@/static/assets/images/users/user-4.jpg"),
          name: "Robert Sitton",
          date: "17/1/2019",
          amount: "$116",
          status: "Delivered",
        },
        {
          id: "#14259",
          image: require("@/static/assets/images/users/user-5.jpg"),
          name: "Alberto Jackson",
          date: "18/1/2019",
          amount: "$109",
          status: "Cancel",
        },
        {
          id: "#14260",
          image: require("@/static/assets/images/users/user-6.jpg"),
          name: "David Sanchez",
          date: "15/1/2018",
          amount: "$94",
          status: "Delivered",
        },
        {
          id: "#14261",
          image: require("@/static/assets/images/users/user-2.jpg"),
          name: "Philip Smead",
          date: "15/1/2018",
          amount: "$94",
          status: "Pending",
        },
      ],
      chatMessages: [
        {
          id: 1,
          image: require("@/static/assets/images/users/user-2.jpg"),
          name: "John Deo",
          message: "Hello!",
          time: "10:00",
        },
        {
          id: 2,
          image: require("@/static/assets/images/users/user-3.jpg"),
          name: "Smith",
          message: "Hi, How are you? What about our next meeting?",
          time: "10:01",
          odd: true,
        },
        {
          id: 3,
          image: require("@/static/assets/images/users/user-2.jpg"),
          name: "John Deo",
          message: "Yeah everything is fine",
          time: "10:01",
        },
        {
          id: 4,
          image: require("@/static/assets/images/users/user-3.jpg"),
          name: "Smith",
          message: "Wow that's great",
          time: "10:02",
          odd: true,
        },
        {
          id: 5,
          image: require("@/static/assets/images/users/user-2.jpg"),
          name: "John Deo",
          message: "Yup!",
          time: "10:03",
        },
      ],
      activityData: [
        {
          date: "Jan 22",
          text: "Responded to need “Volunteer Activities”",
        },
        {
          date: "Jan 20",
          text: "At vero eos et accusamus et iusto odio dignissimos ducimus qui deleniti atque... ",
        },
        {
          date: "Jan 19",
          text: "Joined the group “Boardsmanship Forum”",
        },
        {
          date: "Jan 17",
          text: "Responded to need “In-Kind Opportunity”",
        },
        {
          date: "Jan 16",
          text: "Sed ut perspiciatis unde omnis iste natus error sit rem.",
        },
      ],
    };
  },
});
