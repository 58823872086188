
import { Component, Vue } from 'vue-property-decorator'
import DatePicker from "vue2-datepicker";
import Switches from "vue-switches";
import Multiselect from "vue-multiselect";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/component_samples/page-header.vue";
import appConfig from "@/app.config.json";

/**
 * Form Advanced component
 */

@Component({
  metaInfo: {
    title: "Form Advanced",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {    
    DatePicker, Switches, Multiselect, Layout, PageHeader
  }  
})
class Advanced extends Vue{
  title = "Form Advanced"
  items: Array<object> = [
    {
      text: "Veltrix",
      href: "/"
    },
    {
      text: "Forms",
      href: "/"
    },
    {
      text: "Form Advanced",
      active: true
    }
  ]

  defaultdate = ""
  autoClose = ""
  daterange = ""
  demoMonth = ""
  demoYear = ""
  time =  ""
  enabled = false
  enabled2 =  false
  enabled1 = false
  enabled3 = false
  enabled4 = false
  enabled5 = false
  small = false
  small1 = false
  small2 = false
  small3 = false
  danger = false
  info = false
  success = false
  primary = false
  secondary = false
  customColor = false
  customColor1 = false
  customColor2 = false
  customColor3 = false
  value: any = null
  value1: any = null

  options: Array<string> = [
        "Alaska",
        "Hawaii",
        "California",
        "Nevada",
        "Oregon",
        "Washington",
        "Arizona",
        "Colorado",
        "Idaho",
        "Montana",
        "Nebraska",
        "New Mexico",
        "North Dakota",
        "Utah",
        "Wyoming",
        "Alabama",
        "Arkansas",
        "Illinois",
        "Iowa"
  ]

  maxlen = ""
  data = ""
  option = ""
  textarea = ""
  
  // data() {
  //   return {
  //     title: "Form Advanced",
  //     items: [
  //       {
  //         text: "Veltrix",
  //         href: "/"
  //       },
  //       {
  //         text: "Forms",
  //         href: "/"
  //       },
  //       {
  //         text: "Form Advanced",
  //         active: true
  //       }
  //     ],
  //     defaultdate: "",
  //     autoClose: "",
  //     daterange: "",
  //     demoMonth: "",
  //     demoYear: "",
  //     time: "",
  //     enabled: false,
  //     enabled1: false,
  //     enabled2: false,
  //     enabled3: false,
  //     enabled4: false,
  //     enabled5: false,
  //     small: false,
  //     small1: false,
  //     small2: false,
  //     small3: false,
  //     danger: false,
  //     info: false,
  //     success: false,
  //     primary: false,
  //     secondary: false,
  //     customColor: false,
  //     customColor1: false,
  //     customColor2: false,
  //     customColor3: false,
  //     value: null,
  //     value1: null,
  //     options: [
  //       "Alaska",
  //       "Hawaii",
  //       "California",
  //       "Nevada",
  //       "Oregon",
  //       "Washington",
  //       "Arizona",
  //       "Colorado",
  //       "Idaho",
  //       "Montana",
  //       "Nebraska",
  //       "New Mexico",
  //       "North Dakota",
  //       "Utah",
  //       "Wyoming",
  //       "Alabama",
  //       "Arkansas",
  //       "Illinois",
  //       "Iowa"
  //     ],
  //     maxlen: "",
  //     data: "",
  //     option: "",
  //     textarea: ""
  //   };
  // }
}

export default Advanced
