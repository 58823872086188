
import { Component, Vue } from 'vue-property-decorator';

import Layout from "../../layouts/main.vue";
import PageHeader from "@/component_samples/page-header.vue";
import appConfig from "@/app.config.json";

/**
 * Font-awesome component
 */
@Component({
  metaInfo: {
   title: "Font Awesome",
   meta: [{ name: "description", content: appConfig.description }]
 },
 components: { Layout, PageHeader },
})
class FontAwesome extends Vue {
  title = "Font Awesome";
  items = [
    {
      text: "Veltrix",
      href: "/"
    },
    {
      text: "Icons",
      href: "/"
    },
    {
      text: "Font Awesome",
      active: true
    }
  ];
}

export default FontAwesome;
