






import { Vue, Component } from "nuxt-property-decorator";
import { GChart } from "vue-google-charts";
// import { store } from "../store";
// let etherkey = process.env.ETHERSCAN_API_KEY;

var info;
@Component({
  components: {
    GChart,
  },
})
export default class Percentage extends Vue {
  data: any = {};
  options = {
    title: null,
    pieHole: 0.4,
    pieStartAngle: 90,
    chartArea: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 40,
    },
    colors: ["#71BE45", "333333"],
    enableInteractivity: false,
    legend: "bottom",
    // height: 400
  };

  // computed: {
  //   results() {
  //     return store.state.results;
  //   },
  // },
  async getData() {
    try {
      let response = await fetch(
        `https://api.etherscan.io/api?module=stats&action=tokensupply&contractaddress=0xb2089a7069861c8d90c8da3aacab8e9188c0c531&apikey=IDS49N8H2FNX28VE9V7ZBCW2KACDY5XMRF`
      );
      let results = await response.json();
      // console.log(this.results)
      info = parseInt(
        ((results.result / 100000000000000000) * 2).toPrecision(4)
      );
      this.fillData(info);
      // console.log(info);
    } catch (error) {
      console.log(error);
    }
  }
  fillData(info) {
    this.data = {
      cols: [
        { label: "Task", type: "string" },
        { label: "Amount", type: "number" },
      ],
      rows: [
        { c: [{ v: "Green In Circulation" }, { v: info }] },
        { c: [{ v: "Green To Be Distributed" }, { v: 100 - info }] },
      ],
    };
  }
  mounted() {
    this.getData();
  }
}
