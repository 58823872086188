
import { Component, Vue } from "vue-property-decorator";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/component_samples/page-header.vue";
import appConfig from "@/app.config.json";

/**
 * Carousel component
 */
@Component({
  metaInfo: {
    title: "Carousel",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
})
class Carousel extends Vue {
  title = "Carousel";
  items = [
    {
      text: "Veltrix",
      href: "/",
    },
    {
      text: "UI Elements",
      href: "/",
    },
    {
      text: "Carousel",
      active: true,
    },
  ];
  slide = 0;
  slide1 = 0;
  sliding = null;
}

export default Carousel;
