
import { Component, Vue } from "vue-property-decorator";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/component_samples/page-header.vue";
import appConfig from "@/app.config.json";

import { tableData } from "./data-advancedtable";

/**
 * Advanced table component
 */
@Component({
  metaInfo: {
    title: "Advanced Table",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
})
class AdvancedTable extends Vue {
  tableData = tableData;
  title: string = "Advanced Table";
  items = [
    {
      text: "Veltrix",
      href: "/",
    },
    {
      text: "Tables",
      href: "/",
    },
    {
      text: "Advanced",
      active: true,
    },
  ];
  totalRows = 1;
  currentPage = 1;
  perPage = 10;
  pageOptions = [10, 25, 50, 100];
  filter = null;
  filterOn = [];
  sortBy = "age";
  sortDesc = false;
  fields: Field[] = [
    { key: "name", sortable: true },
    { key: "position", sortable: true },
    { key: "office", sortable: true },
    { key: "age", sortable: true },
    { key: "date", sortable: true },
    { key: "salary", sortable: true },
  ];

  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  }

  /**
   * Total no. of records
   */
  get rows() {
    return this.tableData.length;
  }

  /**
   * Search the table data with search input
   */
  onFiltered(filteredItems) {
    // Trigger pagination to update the number of buttons/pages due to filtering
    this.totalRows = filteredItems.length;
    this.currentPage = 1;
  }
}

type Field = {
  key: string;
  sortable: boolean;
};

export default AdvancedTable;
