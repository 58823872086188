import { MutationTree, ActionTree, GetterTree } from "vuex";
import { EFetchStatus } from "../types";

////////// types used for both header and detail reports //////////

export interface ErrorResponse {
  message: string;
  code: string;
  stack?: string;
}

enum BrandsEnum {
  CONNECT,
  GREEN,
  BLUE,
  SWITCH,
  DIGR,
  GIVE,
  LIBERTY,
}

export interface cartTransactionsTotalByProductName {
  productName: string;
  total: string;
}

////////// MultiBrand HEADER report //////////

export interface multiBrandGetCartTransactionsHeaderReportResponse {
  brand: BrandsEnum;
  headerReportOrError: CartTransactionsHeaderReportOrError;
}

export interface CartTransactionsHeaderReportOrError {
  // __typename: string;
  cartTransactionsHeaderReportResponse?: cartTransactionsHeaderReportResponse;
  error?: ErrorResponse;
}

export interface cartTransactionsHeaderReportResponse {
  totalRevenue: string;
  bundleProductsTotals: cartTransactionsTotalByProductName[];
  noBundleProductsTotals: cartTransactionsTotalByProductName[];
}

////////// MultiBrand DETAIL report //////////

export interface multiBrandGetCartTransactionsDetailReportResponse {
  brand: BrandsEnum;
  detailReportOrError: CartTransactionsDetailReportOrError;
}

export interface CartTransactionsDetailReportOrError {
  // __typename: string; typename returns whether that particular response is a success or an error
  cartTransactionsDetailReportResponse?: cartTransactionsDetailReportResponse;
  error?: ErrorResponse;
}

export interface cartTransactionsDetailReportResponse {
  totalRevenue: string;
  bundleProductsTotals: cartTransactionsTotalByProductName[];
  noBundleProductsTotals: cartTransactionsTotalByProductName[];
}

////////// State object //////////

export interface IMultiBrandState {
  multiBrandHeaderTransactions: any;
  statusMultiBrandHeaderTransactions: EFetchStatus;
  multiBrandDetailTransactions: any;
  statusMultiBrandDetailTransactions: EFetchStatus;
}

export const state: IMultiBrandState = {
  multiBrandHeaderTransactions: [],
  multiBrandDetailTransactions: [],
  statusMultiBrandHeaderTransactions: EFetchStatus.nostate,
  statusMultiBrandDetailTransactions: EFetchStatus.nostate,
};

////////// mutations, getters, actions //////////

export const mutations: MutationTree<IMultiBrandState> = {
  setMultiBrandHeaderTransactions(state, data: any) {
    Object.assign(state.multiBrandHeaderTransactions, data);
    state.statusMultiBrandHeaderTransactions = EFetchStatus.success;
  },
  setMultiBrandDetailTransactions(state, data: any) {
    Object.assign(state.multiBrandDetailTransactions, data);
    state.statusMultiBrandDetailTransactions = EFetchStatus.success;
  },
  setFetchMultiBrandHeaderTransactions(state, data: EFetchStatus) {
    state.statusMultiBrandHeaderTransactions = data;
  },
  setFetchMultiBrandDetailTransactions(state, data: EFetchStatus) {
    state.statusMultiBrandDetailTransactions = data;
  },
};

export const getters: GetterTree<IMultiBrandState, {}> = {
  getMultiBrandHeaderTransactions(state) {
    return state.multiBrandHeaderTransactions;
  },
  getMultiBrandDetailTransactions(state) {
    return state.multiBrandDetailTransactions;
  },
};

////////// imported queries //////////

import getMultiBrandHeaderTransactionsQuery from "@/queries/multiBrandGetCartTransactionsHeaderReport.gql";
import getMultiBrandDetailTransactionsQuery from "@/queries/multiBrandGetCartTransactionsDetailReport.gql";
import { apolloClient } from "@/apolloClient";

export const actions: ActionTree<IMultiBrandState, {}> = {
  async getMultiBrandHeaderTransactionsData({ commit }: any) {
    try {
      commit("setFetchMultiBrandHeaderTransactions", EFetchStatus.fetching);
      const client = apolloClient;
      const {
        data: { multiBrandGetCartTransactionsHeaderReport },
      } = await client.query({
        query: getMultiBrandHeaderTransactionsQuery,
        fetchPolicy: "network-only",
        variables: {
          brandList: [{ startDate: 0, endDate: Date.now() }],
        },
      });
      ////////// only return successful queries //////////
      const validMultiBrandHeader =
        multiBrandGetCartTransactionsHeaderReport.filter(
          (b) => b.headerReportOrError.__typename !== "ErrorResponse"
        );

      commit("setMultiBrandHeaderTransactions", validMultiBrandHeader);
      commit("setFetchMultiBrandHeaderTransactions", EFetchStatus.success);
    } catch (error: any) {
      commit("setFetchMultiBrandHeaderTransactions", EFetchStatus.failed);
      commit("updateSnackbarErrorText", error.message, { root: true });
      console.log(state.statusMultiBrandHeaderTransactions);
    }
  },

  async getMultiBrandDetailTransactionsData({ commit }: any) {
    try {
      commit("setFetchMultiBrandDetailTransactions", EFetchStatus.fetching);
      const client = apolloClient;
      const {
        data: { multiBrandGetCartTransactionsDetailReport },
      } = await client.query({
        query: getMultiBrandDetailTransactionsQuery,
        fetchPolicy: "network-only",
        variables: {
          brandList: [{ startDate: 0, endDate: Date.now() }],
        },
      });

      ////////// only return successful queries //////////
      const validMultiBrandDetail =
        multiBrandGetCartTransactionsDetailReport.filter(
          (d) => d.detailReportOrError.__typename !== "ErrorResponse"
        );
      const cartTransactions: any[] = [];
      validMultiBrandDetail.forEach((brandTransactions) => {
        brandTransactions.detailReportOrError.cartTransactions.forEach(
          (transaction) => {
            cartTransactions.push({
              brand: brandTransactions.brand,
              ...transaction,
            });
          }
        );
      }, []);

      commit("setMultiBrandDetailTransactions", cartTransactions);
      commit("setFetchMultiBrandDetailTransactions", EFetchStatus.success);
    } catch (error: any) {
      commit("setFetchMultiBrandDetailTransactions", EFetchStatus.failed);
      commit("updateSnackbarErrorText", error.message, { root: true });
    }
  },
};
