

















import { Vue, Component } from 'nuxt-property-decorator';

@Component
export default class Holders extends Vue {
  results: any = [];
  userCount = '0';
  async getData() {
    try {
      let response = await fetch(
        'https://api.covalenthq.com/v1/1/tokens/0xb2089a7069861c8d90c8da3aacab8e9188c0c531/token_holders/?&key=ckey_1f5f9055042e4519bc3dac29c6f'
      );
      this.results = await response.json();
      this.userCount = this.results.data.pagination.total_count;
    } catch (error) {
      console.log(error);
    }
  }
  created() {
    this.getData();
  }
}
