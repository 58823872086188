
import { Component, mixins, Prop } from 'nuxt-property-decorator';
import appConfig from '@/app.config.json';
import { DownloadCSV } from '@/mixins';

@Component({
  metaInfo: {
    title: 'Report Table',
    meta: [{ name: 'description', content: appConfig.description }],
  },
})
export default class GeneralTable extends mixins(DownloadCSV) {
  @Prop() tableTitle!: string;
  @Prop() tableData!: object[];
  @Prop() fields!: Field[];

  totalRows = 0;
  currentPage = 1;
  perPage = 25;
  pageOptions = [10, 25, 50, 100];
  filter = '';
  filterOff = ['rawData'];
  sortBy = this.fields[0].key;
  sortDesc = false;

  modalInfo = '';
  modalInfo2 = '';

  get filterOn() {
    return /eth|btc/.test(this.filter) ? ['currency'] : [];
  }

  get rows() {
    // this is auditing row changes between search filtering and date filtering
    // totalRows changes when using search filter
    // tableData.length changes when filtering by date
    return this.totalRows > 0
      ? this.totalRows < this.tableData.length
        ? this.totalRows
        : this.tableData.length
      : this.tableData.length;
  }

  onFiltered(filteredItems) {
    // Trigger pagination to update the number of buttons/pages due to filtering
    this.totalRows = filteredItems.length;
    this.currentPage = 1;
  }

  formatDate(date) {
    let d = new Date(date.value).toLocaleDateString();
    if (d.includes('Invalid Date')) d = '';
    return d;
  }

  tempDataRender(data) {
    return JSON.parse(data.value);
  }

  addressUrl(data) {
    return 'https://etherscan.io/address/'+ data;
  }
}

type Field = {
  key: string;
  sortable: boolean;
};
