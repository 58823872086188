import { Component, Vue, Mutation } from 'nuxt-property-decorator';

// You can declare a mixin as the same style as components.
@Component
export default class CopyToClipboard extends Vue {
  @Mutation toggleSuccessSnackbar!: (payload?: boolean) => void;
  @Mutation updateSnackbarSuccessText!: (args: any) => void;
  @Mutation toggleErrorSnackbar!: (payload?: boolean) => void;
  @Mutation updateSnackbarErrorText!: (args: any) => void;

  copyToClipboard(value: string): void {
    try {
      // Create an auxiliary hidden input
      const aux = document.createElement('input');
      // Stop focusin event on element to allow background elements wrapping
      aux.addEventListener('focusin', (e) => e.stopPropagation());
      // Get the text from the element passed into the input
      aux.setAttribute('value', value.trim());
      // Append the aux input to the body
      document.body.appendChild(aux);
      // Highlight the content
      aux.select();
      // Execute the copy command
      document.execCommand('copy');
      // Remove the input from the body
      document.body.removeChild(aux);

      this.updateSnackbarSuccessText('Copied');
      this.toggleSuccessSnackbar();
    } catch (err) {
      this.updateSnackbarErrorText('Unable to copy.');
      this.toggleErrorSnackbar();
    }
  }
}
