
import { Component, Vue } from "vue-property-decorator";

import appConfig from "@/app.config.json";

/**
 * Recover password-1 component
 */
@Component({
  metaInfo: {
    title: "Recover password-1",
    meta: [{ name: "description", content: appConfig.description }],
  },
})
class RecoverPassword1 extends Vue {}

export default RecoverPassword1;
