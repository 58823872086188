
import { Component, Vue } from "vue-property-decorator";

import appConfig from "@/app.config.json";

/**
 * Login-2 component
 */
@Component({
  metaInfo: {
    title: "Login-2",
    meta: [{ name: "description", content: appConfig.description }],
  },
})
class Login2 extends Vue {}

export default Login2;
