
// import "@fullcalendar/core/vdom";
import Vue from "vue";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";
import { required } from "vuelidate/lib/validators";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/component_samples/page-header.vue";
import appConfig from "@/app.config.json";

import { calendarEvents } from "./data";

export default Vue.extend({
  metaInfo: {
    title: "Calendar",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    FullCalendar,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Calendar",
      items: [
        {
          text: "Veltrix",
          href: "/",
        },
        {
          text: "Calendar",
          active: true,
        },
      ],
      calendarWeekends: true,
      calendarPlugins: [
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin,
        bootstrapPlugin,
        listPlugin,
      ],
      themeSystem: "bootstrap",
      calendarEvents: calendarEvents,
      showmodal: false,
      submitted: false,
      newEventData: {},
      date: new Date(),
      event: {
        title: "test",
      },
    };
  },
  validations: {
    event: {
      title: { required },
    },
  },
  methods: {
    /**
     * Date clicked and show the modal
     */
    dateClicked(arg: any) {
      this.newEventData = arg;
      this.showmodal = true;
      this.date = arg.date;
    },

    /**
     * Modal form submit
     */
    handleSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        const title = this.event.title;
        this.calendarEvents = this.calendarEvents.concat({
          id: this.calendarEvents.length + 1,
          title: title,
          start: this.date,
          backgroundColor: "#626ed4",
        });
        this.showmodal = false;
        this.newEventData = {};
      }
      this.submitted = false;
      this.event = { title: "" };
    },

    /**
     * Hide modal along with form reset
     */
    hideModal() {
      this.submitted = false;
      this.showmodal = false;
      this.event = { title: "" };
    },
  },
});
