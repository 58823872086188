
import Vue from "vue";
import { brandLogo, brandIcon } from "@/middleware/helpers";
import { authMethods } from "@/state/helpers";
import appConfig from "@/app.config.json";

export default Vue.extend({
  metaInfo: {
    title: "Forgot Password",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      icon: brandIcon,
      logo: brandLogo,
      email: "",
      error: null,
      tryingToReset: false,
      isResetError: false,
      isResetSuccess: false,
    };
  },
  methods: {
    ...authMethods,
    tryToReset() {
      this.tryingToReset = true;
      this.error = null;
      return (
        this.resetPassword({
          email: this.email,
        })
          // eslint-disable-next-line no-unused-vars
          .then((token) => {
            this.tryingToReset = false;
            this.isResetError = false;
            this.isResetSuccess = true;
            this.email = "";
          })
          .catch((error: any) => {
            this.tryingToReset = false;
            this.error = error ? error : "";
            this.isResetError = true;
          })
      );
    },
  },
});
