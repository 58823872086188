
import { Component, Vue, Mutation, Watch } from 'nuxt-property-decorator';

import Layout from '@/router/layouts/main.vue';
import PageHeader from '@/components/page-header.vue';
import appConfig from '@/app.config.json';

@Component({
  metaInfo: {
    title: 'Internal Dash',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: { Layout, PageHeader },
})
export default class internalDash extends Vue {
  @Mutation toggleErrorSnackbar!: (payload?: boolean) => void;

  // variable here
  
  async created() {
    // todo
  }

  title = 'Internal';
  items = [
    // breadcrumbs
    {
      text: 'Dashboard',
      href: '/',
    },
    {
      text: this.title,
      active: true,
    },
  ];
}
