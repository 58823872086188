
import { Component, Vue } from "vue-property-decorator";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/component_samples/page-header.vue";
import appConfig from "@/app.config.json";

/**
 * Form Repeater component
 */
@Component({
  metaInfo: {
    title: "Form Repeater",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
})
class FormRepeater extends Vue {
  title: string = "Form Repeater";
  items: Array<{ text: string; href?: string; active?: boolean }> = [
    {
      text: "Veltrix",
      href: "/",
    },
    {
      text: "Forms",
      href: "/",
    },
    {
      text: "Form Repeater",
      active: true,
    },
  ];
  fields: FormData[] = [
    { id: 1, name: "", email: "", subject: "", message: "" },
  ];
  phoneData: PhoneData[] = [{ id: 1, phone: "" }];

  /**
   * Push the row in form
   */
  AddformData() {
    this.fields.push({
      id: this.fields[this.fields.length - 1].id + 1,
      name: "",
      email: "",
      subject: "",
      message: "",
    });
  }

  /**
   * Delete the row
   */
  deleteRow(index) {
    if (confirm("Are you sure you want to delete this element?"))
      this.fields.splice(index, 1);
  }

  /**
   * Add the phone number in form
   */
  AddPhoneNo() {
    this.phoneData.push({
      id: this.phoneData[this.phoneData.length - 1].id + 1,
      phone: "",
    });
  }

  /**
   * Delete the row from form
   */
  deletePhone(index) {
    if (confirm("Are you sure you want to delete this element?")) {
      this.phoneData.splice(index, 1);
    }
  }
}

type FormData = {
  id: number;
  name: string;
  email: string;
  subject: string;
  message: string;
};

type PhoneData = {
  id: number;
  phone: string;
};

export default FormRepeater;
