import { MutationTree, ActionTree } from 'vuex';

interface LayoutState {
    layoutType: 'vertical'
}

export const state: LayoutState = {
    layoutType: 'vertical',
}

export const getters = {}

export const mutations: MutationTree<LayoutState> = {
    CHANGE_LAYOUT(state, layoutType) {
        state.layoutType = layoutType;
    },
}

export const actions: ActionTree<LayoutState, {}> = {
    // eslint-disable-next-line no-unused-vars
    changeLayoutType({ commit, state, rootState }, { layoutType }) {
        commit('CHANGE_LAYOUT', layoutType);
    },
}
