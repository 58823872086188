
import Vue from 'vue';
import appConfig from '@/app.config.json';
import { Component, Prop } from 'nuxt-property-decorator';

@Component({
  metaInfo: {
    title: 'Report Table',
    meta: [{ name: 'description', content: appConfig.description }],
  },
})
export default class LoaderBlock extends Vue {
  @Prop({ type: String, default: '20' }) size!: string;
}
