
import Vue from 'vue';
import Layout from "../../layouts/main.vue";
import PageHeader from "@/component_samples/page-header.vue";
import appConfig from "@/app.config.json";

import {
  linewithDataChart,
  stackedAreaChart,
  basicColumChart,
  nagativeValueBarChart,
  lineColumAreaChart,
  simpleBubbleChart,
  simplePieChart,
  basicRadialBarChart,
  strokedCircularGuage
} from "./data-apex";

/**
 * Apex-chart component
 */
export default Vue.extend({
  metaInfo: {
    title: "Apex chart",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      linewithDataChart: linewithDataChart,
      stackedAreaChart: stackedAreaChart,
      basicColumChart: basicColumChart,
      nagativeValueBarChart: nagativeValueBarChart,
      lineColumAreaChart: lineColumAreaChart,
      simplePieChart: simplePieChart,
      simpleBubbleChart: simpleBubbleChart,
      basicRadialBarChart: basicRadialBarChart,
      strokedCircularGuage: strokedCircularGuage,
      title: "Apexcharts",
      items: [
        {
          text: "Veltrix",
          href: "/"
        },
        {
          text: "Charts",
          href: "/"
        },
        {
          text: "Apexcharts",
          active: true
        }
      ]
    };
  }
});
