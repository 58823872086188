
import Vue from 'vue';

import Layout from "../../layouts/main.vue";
import PageHeader from "@/component_samples/page-header.vue";
import appConfig from "@/app.config.json";

/**
 * Alert Email component
 */
export default Vue.extend({
  metaInfo: {
    title: "Alert Email",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Alert Email",
      items: [
        {
          text: "Veltrix",
          href: "/"
        },
        {
          text: "Email Templates",
          href: "/"
        },
        {
          text: "Alert Email",
          active: true
        }
      ]
    };
  }
});
