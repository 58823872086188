
import { Component, Vue } from "vue-property-decorator";
import { FormWizard, TabContent } from "vue-form-wizard";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/component_samples/page-header.vue";
import appConfig from "@/app.config.json";

/**
 * Form Wizard component
 */
export default Vue.extend({
  metaInfo: {
    title: "Form Wizard",
    meta: [{ name: "description", content: appConfig.description }],
  },
  // @ts-ignore
  components: { Layout, PageHeader, FormWizard, TabContent },
  data() {
    return {
      title: "Form Wizard",
      items: [
        {
          text: "Veltrix",
          href: "/",
        },
        {
          text: "Forms",
          href: "/",
        },
        {
          text: "Form Wizard",
          active: true,
        },
      ],
    };
  },
});
