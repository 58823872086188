
import Vue from 'vue';
import ECharts from "vue-echarts";
import "echarts/lib/component/polar";
import "echarts/lib/chart/line";
import "echarts/lib/chart/pie";
import "echarts/lib/chart/scatter";
import "echarts/lib/component/legend";
import "echarts/lib/component/title";
import "echarts/lib/component/toolbox";
import "echarts/lib/chart/bar";

import Layout from "../../../layouts/main.vue";
import PageHeader from "@/component_samples/page-header.vue";
import appConfig from "@/app.config.json";

import {
  polarchartdata,
  lineChart,
  barChart,
  pieChart,
  donutChart,
  areaChart
} from "./data-echart";

/**
 * E - chart component
 */
export default Vue.extend({
  metaInfo: {
    title: "E - chart",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
    "v-chart": ECharts
  },
  data() {
    return {
      polarchartdata: polarchartdata,
      lineChart: lineChart,
      barChart: barChart,
      pieChart: pieChart,
      donutChart: donutChart,
      areaChart: areaChart,
      title: "E - Charts",
      items: [
        {
          text: "Veltrix",
          href: "/"
        },
        {
          text: "Charts",
          href: "/"
        },
        {
          text: "E - Charts",
          active: true
        }
      ]
    };
  }
});
