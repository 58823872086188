
import Vue from "vue";
import { authMethods } from "@/state/helpers";
import appConfig from "@/app.config.json";
import { brandLogo, brandIcon, copyright } from "@/middleware/helpers";

/**
 * Register component
 */
export default Vue.extend({
  metaInfo: {
    title: "Register",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      icon: brandIcon,
      logo: brandLogo,
      copy: copyright,
      username: "",
      email: "",
      password: "",
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,
    };
  },
  methods: {
    ...authMethods,
    // Try to register the user in with the email, username
    // and password they provided.
    tryToRegisterIn() {
      this.tryingToRegister = true;
      // Reset the regError if it existed.
      this.regError = null;
      return (
        this.register({
          email: this.email,
          password: this.password,
        })
          // eslint-disable-next-line no-unused-vars
          .then((token) => {
            this.isRegisterError = false;
            this.registerSuccess = true;
            setTimeout(() => { // this just gives the user a second to be informed that account creation was successful
              this.tryingToRegister = false;
              // const redirectRoute: string = this.$route.query.redirectFrom?.toString();
              // this.$router.push(redirectRoute || { name: "home" });
            }, 1500);
          })
          .catch((error: any) => {
            this.tryingToRegister = false;
            this.regError = error ? error : "";
            console.log(error);
            this.isRegisterError = true;
          })
      );
    },
  },
});
