
import { Component, Vue, Mixins } from 'vue-property-decorator'
import { Radar } from 'vue-chartjs';

/**
 * Radar chart component
 */
@Component
class RadarChart extends Mixins(Vue, Radar) {
  mounted(): void {
    this.renderChart(
      {
        labels: [
          "Eating",
          "Drinking",
          "Sleeping",
          "Designing",
          "Coding",
          "Cycling",
          "Running"
        ],
        datasets: [
          {
            label: "Desktops",
            backgroundColor: "rgba(2, 164, 153, 0.2)",
            borderColor: "#02a499",
            pointBackgroundColor: "#02a499",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "#02a499",
            data: [65, 59, 90, 81, 56, 55, 40]
          },
          {
            label: "Tablets",
            backgroundColor: "rgba(60, 76, 207, 0.2)",
            borderColor: "#3c4ccf",
            pointBackgroundColor: "#3c4ccf",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "#3c4ccf",
            data: [28, 48, 40, 19, 96, 27, 100]
          }
        ]
      },
      {
        maintainAspectRatio: true,
        legend: {
          position: "top"
        }
      }
    );
  }
}

export default RadarChart;
