
import { Component, Vue } from "vue-property-decorator";

import appConfig from "@/app.config.json";

/**
 * Lock-screen-1 component
 */
@Component({
  metaInfo: {
    title: "Lock screen-1",
    meta: [{ name: "description", content: appConfig.description }],
  },
})
class LockScreen1 extends Vue {}

export default LockScreen1;
