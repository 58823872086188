
import { Component, Vue } from 'vue-property-decorator'

import Layout from "../../layouts/main.vue";
import PageHeader from "@/component_samples/page-header.vue";
import appConfig from "@/app.config.json";

/**
 * Dripicons component
 */
@Component({
  metaInfo: {
    title: "Dripicons",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
})
class DripIcons extends Vue {
  title: string = "Dripicons";
  items = [
    {
      text: "Veltrix",
      href: "/"
    },
    {
      text: "Icons",
      href: "/"
    },
    {
      text: "Dripicons",
      active: true
    }
  ]
}

export default DripIcons;
