
import { Component, Vue, State, Action } from "nuxt-property-decorator";
import appConfig from "@/app.config.json";
import { brandIcon } from "@/middleware/helpers";
import LoaderBlock from "@/components/loader.vue";

@Component({
  metaInfo: {
    title: "Accounting Dashboard",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { LoaderBlock },
})
export default class AccountingCard extends Vue {
  @Action("getMultiBrandHeaderTransactionsData", {
    namespace: "multiBrandSalesData",
  })
  readonly getMultiBrandHeaderTransactionsData!: () => Promise<void>;
  @State(
    (multiBrandSalesData) => multiBrandSalesData.multiBrandHeaderTransactions,
    {
      namespace: "multiBrandSalesData",
    }
  )
  readonly multiBrandHeaderTransactions!: any;

  @State(
    (multiBrandSalesData) =>
      multiBrandSalesData.statusMultiBrandHeaderTransactions,
    {
      namespace: "multiBrandSalesData",
    }
  )
  readonly isFetching!: any;

  async created(): Promise<void> {
    await this.getMultiBrandHeaderTransactionsData();
  }

  data() {
    return {
      startDate: "",
      endDate: "",
      brandIcon: brandIcon,
    };
  }
}
