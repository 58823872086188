
import { Component, Vue } from 'vue-property-decorator';
import Layout from "../../layouts/main.vue";
import PageHeader from "@/component_samples/page-header.vue";
import appConfig from "@/app.config.json";

import Chart from "@/component_samples/widgets/chart.vue";

import {
  overlappingBarChart,
  stackBarChart,
  donutAnimateChart,
  simplePieChart,
  smilAnimationChart,
  simpleLineChart
} from "./data-chartist";

/**
 * Chartist Chart component
 */

@Component({
  metaInfo: {
    title: "Chartist chart",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, Chart },
})
class Chartist extends Vue{
  overlappingBarChart = overlappingBarChart;
  stackBarChart = stackBarChart;
  donutAnimateChart = donutAnimateChart;
  simplePieChart = simplePieChart;
  smilAnimationChart = smilAnimationChart;
  simpleLineChart = simpleLineChart;
  title = "Chartist Chart";
  items = [
    {
      text: "Veltrix",
      href: "/"
    },
    {
      text: "Charts",
      href: "/"
    },
    {
      text: "Chartist Chart",
      active: true
    }
  ]
}

export default Chartist;
