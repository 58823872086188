
import {Component, Vue, Mixins } from 'vue-property-decorator'
import { PolarArea } from "vue-chartjs";

/**
 * Polar-area chart component
 */
@Component
class PolarAreaChart extends Mixins(Vue, PolarArea) {
  mounted(): void {
    this.renderChart(
      {
        labels: ["Series 1", "Series 2", "Series 3", "Series 4"],
        datasets: [
          {
            data: [11, 16, 7, 18],
            backgroundColor: ["#38a4f8", "#02a499", "#ec4561", "#3c4ccf"],
            label: "My dataset",
            hoverBorderColor: "#fff"
          }
        ]
      },
      {
        maintainAspectRatio: true,
        legend: {
          position: "top"
        }
      }
    );
  }
}

export default PolarAreaChart;
