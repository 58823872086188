
import { Vue, Component, Mutation } from 'nuxt-property-decorator';
import { namespace } from 'vuex-class';
import { signIn } from '@/middleware/auth';
import {
  redirectSubdomain,
  brandName,
  brandLogo,
  copyright,
} from '@/middleware/helpers';

const authModule = namespace('auth');

import appConfig from '@/app.config.json';
@Component({
  metaInfo: {
    title: 'Login',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {},
})
class Login extends Vue {
  @authModule.Action('logIn') logIn: any;

  email = '';
  password = '';
  showPass = false;
  isFetching = false;

  authError = null;
  tryingToLogIn = false;
  isAuthError = false;

  logo = brandLogo;
  brand = brandName;
  copy = copyright;
  signupLink = '//' + redirectSubdomain('app', '/register');

  async tryToLogIn(): Promise<void> {
    try {
      this.isFetching = true;
      this.authError = null;
      this.isAuthError = false;
      const { walletExists, profile, twoFaEnabled } = await signIn(
        this.email,
        this.password,
        this.$apollo
      );

      this.processLogin(walletExists, profile, twoFaEnabled);
    } catch (error: any) {
      this.password = '';
      this.isFetching = false;
      this.isAuthError = true;
      this.authError = error.message;
    }
  }

  processLogin(walletExists: any, profile: any, twoFaEnabled: any) {
    this.logIn({ profile }).then(() => {
      this.$store.dispatch('userData/getUserData');
      const redirectRoute: string = this.$route.query.redirectFrom?.toString();
      this.$router.push(redirectRoute || { name: 'home' });
    });
  }
}

export default Login;
