
import { Component, Vue } from 'vue-property-decorator'
import Layout from "../../layouts/main.vue";
import PageHeader from "@/component_samples/page-header.vue";
import appConfig from "@/app.config.json";

/**
 * Form Elements component
 */
@Component({
  metaInfo: {
    title: "Form Elements",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader }
})
class Elements extends Vue {    
    title = "Form Elements"
    items: Array<object> = [
      {
        text: "Veltrix",
        href: "/"
      },
      {
        text: "Forms",
        href: "/"
      },
      {
        text: "Form Elements",
        active: true
      }
    ]    
}
export default Elements
