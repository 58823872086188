
import { Component, Vue } from 'vue-property-decorator';

import Layout from "../../layouts/main.vue";
import PageHeader from "@/component_samples/page-header.vue";
import appConfig from "@/app.config.json";

/**
 * Ion Icons component
 */
@Component({
  metaInfo: {
   title: "Ion Icons",
   meta: [{ name: "description", content: appConfig.description }]
 },
 components: { Layout, PageHeader },
})
class IonIcons extends Vue {
  title = "Ion Icons";
  items = [
    {
      text: "Veltrix",
      href: "/"
    },
    {
      text: "Icons",
      href: "/"
    },
    {
      text: "Ion Icons",
      active: true
    }
  ];
}

export default IonIcons;
