
import Vue from 'vue';
import { layoutComputed } from "@/state/helpers";

import Vertical from "@/router_samples/layouts/vertical.vue";
import Horizontal from "@/router_samples/layouts/horizontal.vue";

export default Vue.extend({
  components: { Vertical, Horizontal },
  computed: {
    ...layoutComputed
  }
});
