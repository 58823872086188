
import Vue from 'vue';

/**
 * Widget-stat component -- specify the widget title, image, value, subtext and color of subtext.
 */
export default Vue.extend({
  props: {
    title: {
      type: String,
      default: ""
    },
    image: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    subText: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: ""
    }
  }
});
