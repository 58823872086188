
import {
  Component,
  Vue,
  Getter,
  Mutation,
  Watch,
} from 'nuxt-property-decorator';

import Layout from '@/router/layouts/main.vue';
import PageHeader from '@/components/page-header.vue';
import GeneralTable from '@/components/tables/general-table.vue';
import LoaderBlock from '@/components/loader.vue';
import appConfig from '@/app.config.json';
import { ICartAddressRequest } from '@/state/modules/salesData';
import { EFetchStatus } from '@/state/types';

@Component({
  metaInfo: {
    title: 'Advanced Table',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: { Layout, PageHeader, GeneralTable, LoaderBlock },
})
export default class cartAudit extends Vue {
  @Getter('getRequests', { namespace: 'salesData' })
  readonly _tableData!: (ICartAddressRequest & {
    address: string;
    currency: string;
  })[];

  @Getter('checkRequests', { namespace: 'salesData' })
  readonly _status!: EFetchStatus;

  @Mutation toggleErrorSnackbar!: (payload?: boolean) => void;

  @Watch('_status')
  showError(status) {
    if (status === 2) this.toggleErrorSnackbar();
  }

  @Watch('startDateFilter')
  update(d) {
    console.log('refreshing...');
  }

  endDateFilter = new Date();
  startDateFilter = new Date(
    new Date().setDate(this.endDateFilter.getDate() - 30)
  );

  get tableData() {
    return this._tableData
      .filter(
        (i) =>
          i.created &&
          i.created >= this.startDateFilter &&
          i.created <= this.endDateFilter
      )
      .map((i) => {
        i.address = i.addresses[0]?.address;
        i.currency = i.addresses[0]?.coinSymbol;
        i.userId = i.userId === '' ? '<Missing userID>' : i.userId;
        // i.orderId = i.orderId.replace('mepr.', '');
        return i;
      });
  }

  title = 'Cart Audit (getAllCartAddressRequests)';
  items = [
    // breadcrumbs
    {
      text: 'Sales',
      href: '/',
    },
    {
      text: 'Cart Audit',
      active: true,
    },
  ];
  fields = [
    // table columns
    { key: 'userId', sortable: true },
    { key: 'currency', sortable: true },
    // { key: 'coinSymbol', sortable: true },
    // { key: 'amountUsd', sortable: true },
    // { key: 'amountCrypto', sortable: true },
    // { key: 'quantity', sortable: true },
    { key: 'orderId', sortable: true },
    { key: 'affiliateId', sortable: true },
    { key: 'affiliateSessionId', sortable: true },
    // { key: "utmVariables", sortable: true },
    { key: 'created', sortable: true },
    // { key: 'expires', sortable: true },
    { key: 'address', sortable: false },
  ];
}
