import { RouteConfig, NavigationGuard } from 'vue-router';
import { isLoggedIn } from '@/middleware/auth';
import { logout } from '@/middleware/auth';

interface Meta {
  meta?: {
    beforeResolve?: NavigationGuard;
    [key: string]: any;
  };
}

const Routes: Array<RouteConfig & Meta> = [
  {
    path: '/',
    meta: { authRequired: true },
    name: 'home',
    component: () => import('@/router/views/dashboard.vue'),
  },
  {
    path: '/samples',
    meta: { authRequired: true },
    name: 'Sample Components',
    component: () => import('@/router_samples/views/home.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/router/views/public/login.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (isLoggedIn()) {
          // Redirect to the home page instead
          next({ name: 'home' });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/router/views/public/register.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (isLoggedIn()) {
          // Redirect to the home page instead
          next({ name: 'home' });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/router/views/public/forgot-password.vue'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (isLoggedIn()) {
          // Redirect to the home page instead
          next({ name: 'home' });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        logout();
        next({ name: 'login' });
      },
    },
  },
  {
    path: '/distribution-info',
    name: 'Distribution Info',
    component: () => import('@/router/views/distribution/index.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/cart-sales',
    name: 'Cart Sales',
    component: () => import('@/router/views/sales/cartSales.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/cart-audit',
    name: 'Cart Audit',
    component: () => import('@/router/views/sales/cartAudit.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/accounting-dashboard',
    name: 'Accounting Dashboard',
    component: () => import('@/router/views/sales/accountingDashboard.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/dev-dash',
    name: 'Dev Dashboard',
    component: () => import('@/router/views/devdash/index.vue'),
    meta: {
      authRequired: true,
    },    
  },
  {
    path: '/internal-dash',
    name: 'Internal Dash',
    component: () => import('@/router/views/internal/index.vue'),
    meta: {
      authRequired: true,
    },
  },
  // sample pages...
  {
    path: '/calendar',
    name: 'Calendar',
    component: () => import('@/router_samples/views/calendar/index.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/email/inbox',
    name: 'Email Inbox',
    component: () => import('@/router_samples/views/email/inbox.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/email/read-email',
    name: 'Read email',
    component: () => import('@/router_samples/views/email/reademail.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/email/compose',
    name: 'Compose',
    component: () => import('@/router_samples/views/email/compose.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/ui/alerts',
    name: 'Alerts',
    component: () => import('@/router_samples/views/ui/alerts.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/ui/rating',
    name: 'Rating',
    component: () => import('@/router_samples/views/ui/rating.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/ui/buttons',
    name: 'Buttons',
    component: () => import('@/router_samples/views/ui/buttons.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/ui/cards',
    name: 'Cards',
    component: () => import('@/router_samples/views/ui/cards.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/ui/carousel',
    name: 'Carousel',
    component: () => import('@/router_samples/views/ui/carousel.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/ui/colors',
    name: 'Colors',
    component: () => import('@/router_samples/views/ui/colors.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/ui/dropdowns',
    name: 'Dropdowns',
    component: () => import('@/router_samples/views/ui/dropdowns.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/ui/general',
    name: 'General',
    component: () => import('@/router_samples/views/ui/general.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/ui/grid',
    name: 'Grid',
    component: () => import('@/router_samples/views/ui/grid.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/ui/images',
    name: 'Images',
    component: () => import('@/router_samples/views/ui/images.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/ui/modals',
    name: 'Modals',
    component: () => import('@/router_samples/views/ui/modals.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/ui/progressbar',
    name: 'Progressbar',
    component: () => import('@/router_samples/views/ui/progressbar.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/ui/rangeslider',
    name: 'Range-slider',
    component: () => import('@/router_samples/views/ui/rangeslider.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/ui/sweetalert',
    name: 'Sweet-alert',
    component: () => import('@/router_samples/views/ui/sweetalert.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/ui/tabs',
    name: 'Tabs & Accordions',
    component: () => import('@/router_samples/views/ui/tabs.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/ui/typography',
    name: 'Typography',
    component: () => import('@/router_samples/views/ui/typography.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/ui/video',
    name: 'Video',
    component: () => import('@/router_samples/views/ui/video.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/pages/timeline',
    name: 'Timeline',
    component: () => import('@/router_samples/views/pages/timeline.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/pages/invoice',
    name: 'Invoice',
    component: () => import('@/router_samples/views/pages/invoice.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/pages/pricing',
    name: 'Pricing',
    component: () => import('@/router_samples/views/pages/pricing.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/pages/blank-page',
    name: 'Blank page',
    component: () => import('@/router_samples/views/pages/blank.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/pages/faqs',
    name: 'FAQs',
    component: () => import('@/router_samples/views/pages/faqs.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/pages/directory',
    name: 'Directory',
    component: () => import('@/router_samples/views/pages/directory.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/pages/404',
    name: 'Page-404',
    component: () => import('@/router_samples/views/pages/error-404.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/pages/500',
    name: 'Page-500',
    component: () => import('@/router_samples/views/pages/error-500.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/pages/maintenance',
    name: 'Maintenance',
    component: () => import('@/router_samples/views/pages/maintenance.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/pages/login-1',
    name: 'Login-1',
    component: () => import('@/router_samples/views/pages/login-1.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/pages/login-2',
    name: 'Login-2',
    component: () => import('@/router_samples/views/pages/login-2.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/pages/register-1',
    name: 'Register-1',
    component: () => import('@/router_samples/views/pages/register-1.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/pages/register-2',
    name: 'Register-2',
    component: () => import('@/router_samples/views/pages/register-2.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/pages/recoverpwd-1',
    name: 'Recover Password 1',
    component: () => import('@/router_samples/views/pages/recoverpwd-1.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/pages/recoverpwd-2',
    name: 'Recover Password 2',
    component: () => import('@/router_samples/views/pages/recoverpwd-2.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/pages/lock-screen1',
    name: 'Lock-screen 1',
    component: () => import('@/router_samples/views/pages/lock-screen1.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/pages/lock-screen2',
    name: 'Lock-screen 2',
    component: () => import('@/router_samples/views/pages/lock-screen2.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/form/elements',
    name: 'Form Elements',
    component: () => import('@/router_samples/views/forms/elements.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/form/advanced',
    name: 'Form advanced',
    component: () => import('@/router_samples/views/forms/advanced.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/form/editor',
    name: 'Form editor',
    component: () => import('@/router_samples/views/forms/editor.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/form/mask',
    name: 'Form mask',
    component: () => import('@/router_samples/views/forms/mask.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/form/uploads',
    name: 'Form uploads',
    component: () => import('@/router_samples/views/forms/uploads.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/form/validation',
    name: 'Form validation',
    component: () => import('@/router_samples/views/forms/validation.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/form/repeater',
    name: 'Form Repeater',
    component: () => import('@/router_samples/views/forms/repeater.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/form/wizard',
    name: 'Form wizard',
    component: (): Promise<any> =>
      import('@/router_samples/views/forms/wizard.vue').then((m) => m.default),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/icons/dripicons',
    name: 'Dripicons icons',
    component: () => import('@/router_samples/views/icons/dripicons.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/icons/fontawesome',
    name: 'Font-awesome icons',
    component: () => import('@/router_samples/views/icons/fontawesome.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/icons/ion',
    name: 'Ion icons',
    component: () => import('@/router_samples/views/icons/ion.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/icons/material',
    name: 'Material icons',
    component: () => import('@/router_samples/views/icons/material.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/icons/themify',
    name: 'Themify icons',
    component: () => import('@/router_samples/views/icons/themify.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/icons/typicons',
    name: 'Typicons icons',
    component: () => import('@/router_samples/views/icons/typicons.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/tables/basic',
    name: 'Basic table',
    component: () => import('@/router_samples/views/tables/basic.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/tables/advancedtable',
    name: 'Advanced table',
    component: () => import('@/router_samples/views/tables/advancedtable.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/charts/chartist',
    name: 'Chartist',
    component: () => import('@/router_samples/views/charts/chartist.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/charts/chartjs',
    name: 'Chartjs',
    component: () => import('@/router_samples/views/charts/chartjs/index.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/charts/apex',
    name: 'Apex',
    component: () => import('@/router_samples/views/charts/apex.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/charts/echart',
    name: 'E - Chart',
    component: () => import('@/router_samples/views/charts/echart/index.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/email-template/basic',
    name: 'Email-template Basic',
    component: () => import('@/router_samples/views/email-template/basic.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/email-template/alert',
    name: 'Alert Email',
    component: () => import('@/router_samples/views/email-template/alert.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/email-template/billing',
    name: 'Billing Email',
    component: () =>
      import('@/router_samples/views/email-template/billing.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/maps/google',
    name: 'Google map',
    component: () => import('@/router_samples/views/maps/google.vue'),
    meta: {
      authRequired: true,
    },
  },
];

export default Routes;
