
import { Component, Vue } from "vue-property-decorator";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/component_samples/page-header.vue";
import appConfig from "@/app.config.json";

/**
 * General component
 */
@Component({
  metaInfo: {
    title: "General",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
})
class General extends Vue {
  title = "General";
  items = [
    {
      text: "Veltrix",
      href: "/",
    },
    {
      text: "UI Elements",
      href: "/",
    },
    {
      text: "General",
      active: true,
    },
  ];
}

export default General;
