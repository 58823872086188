
/**
 * Right sidebar component
 */
import Vue from "vue";

export default Vue.extend({
  components: {},
  data() {
    return {
      config: {
        // handler: this.handleRightBarClick,
        // middleware: this.middleware,
        events: ["click"],
      },
    };
  },

  methods: {
    hide() {
      // @ts-ignore
      this.$parent.toggleRightSidebar();
    },
    // eslint-disable-next-line no-unused-vars
    handleRightBarClick() {
      // @ts-ignore
      this.$parent.hideRightSidebar();
    },
    // eslint-disable-next-line no-unused-vars
    middleware(event: any) {
      return !event.target.classList.contains("toggle-right");
    },
  },
});
