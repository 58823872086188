
import {
  Component,
  Vue,
  State,
  Action,
} from "nuxt-property-decorator";
import appConfig from "@/app.config.json";
import LoaderBlock from "@/components//loader.vue";

@Component({
  metaInfo: {
    title: "Accounting Dashboard",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { LoaderBlock },
})
export default class AccountingTable extends Vue {
  @Action("getMultiBrandDetailTransactionsData", {
    namespace: "multiBrandSalesData",
  })
  readonly getMultiBrandDetailTransactionsData!: () => Promise<void>;
  @State(
    (multiBrandSalesData) => multiBrandSalesData.multiBrandDetailTransactions,
    {
      namespace: "multiBrandSalesData",
    }
  )
  readonly multiBrandDetailTransactions!: any;

  @State((multiBrandSalesData) => multiBrandSalesData.statusMultiBrandDetailTransactions, {
    namespace: "multiBrandSalesData",
  })
  readonly isFetching!: any;

  async created(): Promise<void> {
    await this.getMultiBrandDetailTransactionsData();
  }

  data() {
    return {
      fields: [
        { key: "brand", sortable: true, label: "Brand" },
        { key: "name", sortable: true, label: "Name" },
        { key: "email", sortable: true, label: "Email" },
        { key: "productName", sortable: true, label: "Product Name" },
        { key: "address", sortable: true, label: "Address" },
        { key: "cryptoCurrency", sortable: true, label: "Currency" },
        {
          key: "totalCryptoReceived",
          sortable: true,
          label: "Total Currency Received",
        },
        { key: "currentValue", sortable: true, label: "Current Value" },
        { key: "created", sortable: true, label: "Created" },
      ],
      items: [],
    };
  }
}
