
import Vue from "vue";
import Component from "vue-class-component";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/component_samples/page-header.vue";
import appConfig from "@/app.config.json";

/**
 * Buttons component
 */

@Component({
  metaInfo: {
    title: "Buttons",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader }
})
export default class Buttons extends Vue {
  data() {
    return {
      title: "Buttons",
      items: [
        {
          text: "Veltrix",
          href: "/"
        },
        {
          text: "UI Elements",
          href: "/"
        },
        {
          text: "Buttons",
          active: true
        }
      ]
    };
  }
}
