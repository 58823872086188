
import { Component, Vue } from "vue-property-decorator";

import appConfig from "@/app.config.json";

/**
 * Lock-screen2 component
 */
@Component({
  metaInfo: {
    title: "Lock screen-2",
    meta: [{ name: "description", content: appConfig.description }],
  },
})
class LockScreen2 extends Vue {}

export default LockScreen2;
